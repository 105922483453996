import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from "../../../services/api-request.service";
import { Config } from "../../../utils/Config";
import { ActivatedRoute, RouterModule, Router  } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';


@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

	params:any;
	estrutura:any = {};
	campos:any[] = [];
	dados:any = {};
	config:any = Config;
	selects:any[] = [];


	constructor(
		public apiRequest: ApiRequestService,
		private route: ActivatedRoute,
		private router: Router,
		private _notifications: NotificationsService
	) {

		this.route.params.subscribe(ret => {
			this.params = ret;
			this.CarregarEstrutura();
		});

	}

	ngOnInit() {}

	CarregarEstrutura(){
		this.apiRequest.Get("crud/"+this.params.tabela+"/structure").subscribe((ret:any)=>{
			this.estrutura = ret;

			for(let k in this.estrutura.campos){

				// Adiciona os campos que são editáveis e não são do tipo Hidden
				if(this.estrutura.campos[k].editavel && this.estrutura.campos[k].tipo!="hidden"){
					let c = this.estrutura.campos[k];
					c.campo = k;
					this.campos.push(c);
				}

				// Adiciona 
				if (this.estrutura.campos[k].valor!=undefined){
					this.dados[k] = this.estrutura.campos[k].valor;
				}

				// Adiciona 
				if (this.estrutura.campos[k].tipo=="json"){
					this.dados[k] = [];

					//Carrega os dados de select dos jsons
					for(let k2 in this.estrutura.campos[k].campos){
						let l = this.estrutura.campos[k].campos[k2];
						if(l.tipo=="select")
							this.CarregarSelect(l.relacao, l.relacao_campo, l.relacao_filtro );
					}

				}

				if(this.estrutura.campos[k].tipo=="select"){
					this.CarregarSelect(this.estrutura.campos[k].relacao, this.estrutura.campos[k].relacao_campo, this.estrutura.campos[k].relacao_filtro );
				}

			}

			if(this.params.id!=undefined){
				this.CarregarDados();
			}

			//console.log(this.campos);
			//console.log(this.dados);
		});
	}

	CarregarSelect(tabela, campo, filtro=undefined){

		if(this.selects[tabela]!=undefined) return;

		this.selects[tabela] = [];

		let url = "crud/select?table="+tabela+"&field="+campo;

		if(filtro!=undefined){
			url += "&filter="+filtro;
		}

		this.apiRequest.Get(url).subscribe((ret:any)=> {
			this.selects[tabela] = ret;
		}, (error:any)=>{
			console.error("Erro", error);
		});
	}

	CarregarDados(){

		this.apiRequest.Get("crud/"+this.params.tabela+"/"+this.params.id).subscribe((ret:any)=>{

			for(let k in this.estrutura.campos){

				if(this.estrutura.campos[k].tipo=="json"){
					ret[k] = (ret[k]=="" || ret[k]==null) ? [] : JSON.parse( ret[k] );
				}

				if(this.estrutura.campos[k].tipo=="date"){
					ret[k] = new Date( ret[k] + " GMT-03:00" );
				}

				if(this.estrutura.campos[k].tipo=="datetime"){
					ret[k] = new Date( ret[k]  ).toISOString().slice(0,16);
				}

				if(this.estrutura.campos[k].tipo=="status"){
					ret[k] = ret[k]==1;
				}

				if(this.estrutura.campos[k].format=="time"){
					ret[k] = ret[k].substr(0,5);
				}

				if(this.estrutura.campos[k].tipo=="multiple_enum" && (typeof ret[k]==='string') && ret[k]){
					ret[k] = ret[k].split(",");
				}
			}

			this.dados = ret;
			console.log(this.dados);
		});

	}

	RetornaArrayCamposJson(campos:any){
		let c = [];
		for(let k in campos){
			let temp = campos[k];
			temp.campo = k;
			c.push(temp);
		}

		return c;
	}

	JsonAdd(dadosJson:any, estrutura:any[]){
		console.log(dadosJson);
		console.log(estrutura);
		let d = {};
		for(let i:number = 0; i<estrutura.length; i++){
			d[estrutura[i].campo] = "";
		}

		dadosJson.push(d);
	}

	JsonRemove(dadosJson:any, i:number){
		dadosJson.splice(i, 1);
	}

	JsonCheckCond(campo:any){
		if(!campo.condicoes){
			return true;
		} else {
			for(let i:number=0; i<campo.condicoes.length; i++){
				let c = campo.condicoes[i];
				if(this.dados[c.key]==c.value) return true;
			}
		}
	}

	validarCampos() {
		let valid = true;
		let msg = "Preencha os campos obrigatórios *";
		let dadosSalvar = JSON.stringify(this.dados);
		dadosSalvar = JSON.parse(dadosSalvar);
		
		for(let k in this.estrutura.campos) {
			let campo = this.estrutura.campos[k];
			if(campo.obrigatorio) {

				if(campo.indexTable) {
					if(dadosSalvar[k] == undefined || dadosSalvar[k].length == 0) {
						valid = false;
					} else {
						for(let j in dadosSalvar[k]) {
							if(this.selects[campo.relacao].filter((item) => { return item.readonly && item.id == dadosSalvar[k][j]; }).length > 0) {
								dadosSalvar[k].splice(j,1);
							}
						}
						if(dadosSalvar[k].length == 0) {
							if(campo.relacao == "products") { msg = "Pelo menos 1 turma precisa estar marcada de verde."; }
							valid = false;
						}

					}
				} else {
					if(dadosSalvar[k] == undefined || dadosSalvar[k] == "") {
						valid = false;
					}
				}
				
			}
		}
		if(!valid) { alert(msg); }
		return valid;
	}

	Salvar() {		

		if(this.validarCampos()) {
			let url = "crud/"+this.params.tabela;

			if(this.params.id!=undefined){
				url += "/"+this.params.id;
			}

			// PARA COPIAR SEM MANTER REFERENCIA
			let dadosSalvar = JSON.stringify(this.dados);
			dadosSalvar = JSON.parse(dadosSalvar);

			for(let k in this.estrutura.campos){

				if(this.estrutura.campos[k].tipo=="status"){
					dadosSalvar[k] = dadosSalvar[k] ? 1 : 0;
				}

				if(this.estrutura.campos[k].tipo=="json"){
					dadosSalvar[k] = JSON.stringify(dadosSalvar[k]);
				}

				if(this.estrutura.campos[k].tipo=="date"){
					dadosSalvar[k] = dadosSalvar[k].substr(0,10);
				}

				if(this.estrutura.campos[k].tipo=="multiple_enum"){
					dadosSalvar[k] = dadosSalvar[k].join(",");
				}

			}

			this.apiRequest.Post(url, dadosSalvar).subscribe((ret:any)=>{
				console.log(ret);
				
				this._notifications.create("Sucesso!", "Registro salvo com sucesso", NotificationType.Success, { timeOut: 5000 } );

				if(this.estrutura.redirect==undefined){
					this.router.navigate(["/"+this.params.tabela+"/list"])
				} else {
					let url = this.estrutura.redirect.replace("--ID--", this.params.id);
					this.router.navigate([url]);
				}
				//location.replace("/"+this.params.tabela+"/list");
				//history.back();

			});
		}
	}


	Voltar() {
		history.back();
	}

}
