import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiRequestService } from '../../../services/api-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material';

@Component({
  selector: 'app-quiz-list',
  templateUrl: './quiz-list.component.html',
  styleUrls: ['./quiz-list.component.scss']
})
export class QuizListComponent implements OnInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService,
	) {
	}

	painelAberto:boolean = false;

	filtros:any = {
		nome: "",
		codigo: "",
	};

	provas: any = [];

	colunas: string[] = [
		"code",
		"name",
		"courses",
		"actions"
	]

	Filtrar(){
		this.api.Get(`quiz?page=${this.provas.current_page}&pageSize=${this.provas.per_page}&name=${this.filtros.nome}&code=${this.filtros.codigo}`).subscribe((response) => {
			this.provas = response;

			this.formataCursos();
			
			this.paginator.firstPage();
		});
	}

	LimparFiltro() {
		this.filtros = {
			nome: "",
			codigo: "",
		};

		this.Filtrar();
	}

	ngOnInit() {
		this.carregaDados();
	}

	carregaDados() {
		this.api.Get('quiz').subscribe((response) => {
			this.provas = response;
			
			this.formataCursos();
		});
	}

	buscaProvas() {
		this.api.Get(`quiz?page=${this.provas.current_page + 1}&pageSize=${this.provas.per_page}&name=${this.filtros.nome}&code=${this.filtros.codigo}`).subscribe((response) => {
			this.provas = response;

			this.formataCursos();
		});
	}

	formataCursos() {
		this.provas.data.forEach((value, index) => {
			value.courses = value.courses.map((value) => value.name) + ',';

			value.courses = value.courses.slice(0, value.courses.length - 1);
		});
	}

	Deletar(id: number) {
		this.api.Delete(`quiz/${id}`).subscribe(() => {
			this.notifications.create('Prova removida', 'Prova removida com sucesso', NotificationType.Success, {
				timeOut: 2000
			});

			this.LimparFiltro();
		});
	}

	AtualizarPaginacao(evento: PageEvent) {
		this.provas.current_page = evento.pageIndex;
		this.provas.per_page = evento.pageSize;

		this.buscaProvas();
	}
}
