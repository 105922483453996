import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ApiRequestService } from 'src/app/services/api-request.service';
import { User } from "../../../utils/User";
import { MatDialog } from '@angular/material';
import { NotasEditComponent } from '../notas-edit/notas-edit.component';

@Component({
  selector: 'app-notas-list',
  templateUrl: './notas-list.component.html',
  styleUrls: ['./notas-list.component.scss']
})
export class NotasListComponent implements OnInit {

	currentUser: User = new User();
	cursos: any[]; 
	dados:any;

	userName:string = null;
	products:any;
	columnsToDisplay: string[] = ['name'];
  	pagAtual:number = 0;
	turmasList: any[] = [];
	ativarSelecaoTurma: boolean = false;
	idTurma: string = null;
	journeys: any[] = [];
	loading: boolean = false;
	formNotas: FormGroup = this.formBuilder.group({ //Usado temporariamente para salvar a turma selecionada
		curso: ['', Validators.required],
		turmaSelecionada: [{}, Validators.required],
		idTurmaSelecionada: [null],
		dataLiberacaoSelecionada: [null, Validators.required],
		userName:[''],

	});


	constructor(
		public apiRequest: ApiRequestService,
		private formBuilder: FormBuilder,
		private notifications: NotificationsService,
		public dialog: MatDialog

	) { }

	ngOnInit() {
		
		this.CarregarCursos();
		this.CarregarTurmas();
	}

	CarregarNotas(pag:number = 0, pageSize:number = 1000){
		if(this.formNotas.get('turmaSelecionada').value.id){

			let url = "userByClass?turmaId="+this.formNotas.get("turmaSelecionada").value.id+"&page="+(pag+1)+"&pageSize="+pageSize+"&userName="+this.formNotas.get("userName").value;

			this.loading = true;
			this.apiRequest.Get(url).subscribe((ret:any)=>{
				this.dados = ret;
				this.loading = false;
				if (this.dados.data.length > 0) {
					this.journeys = this.dados.data[0].journeys; // Títulos das colunas
					this.columnsToDisplay = ['name', ...this.journeys.map(journey => journey.name)];
				}

			});

			this.pagAtual = pag;
		}else{
			this.notifications.create("Erro!", "Selecione uma turma", NotificationType.Error, { timeOut: 2500 } );
		}

	}

	CarregarCursos(){
  
		  this.apiRequest.Get("cursos").subscribe((response:any[])=>{
			  this.cursos = response;
		  }, error=>{
			  console.error("ERRO", error)
		  });
  
	}

  	CarregarTurmas() {

		this.dados = [];
		this.columnsToDisplay = [];

		if(this.formNotas.get('curso').value){

			
			const url = `cursos/turmas/`+this.formNotas.get('curso').value;
			
			this.apiRequest.Get(url).subscribe((response:any[])=>{
			  this.turmasList = response;
			  if (this.turmasList.length > 0){
				console.log("this.turmasList");
				this.ativarSelecaoTurma = true;
			  }else{
				this.notifications.create("Atenção:", "Turmas não encontradas. Verifique se este curso possui turmas vinculadas", NotificationType.Error, { timeOut: 4500 } );
			  }
			}, error=>{
			  this.turmasList = [];
				this.notifications.create("Erro!", error.message, NotificationType.Error, { timeOut: 2500 } );
			});
			
		}
	}

	//Recebe as informações da célula e o journeyId equivalente a coluna
    getNotaValue(element: any, journeyId: number) {
        const journey = element.journeys.find(j => j.id === journeyId);
        return journey ? journey.nota !=null ? journey.nota : 'Prova não realizada' : 'N/L';
    }
	
	getNotaInfo(element: any, journeyId: number) {
        const journey = element.journeys.find(j => j.id === journeyId);
        return journey ? journey : 'N/L';
    }

	
	editNotaDialog(element: any, journeyId: any) {
		let notaInfo = this.getNotaInfo(element, journeyId);
		
		const dialogRef = this.dialog.open(NotasEditComponent, {
		  width: "350px",
		  data: notaInfo
		});
	
		dialogRef.afterClosed().subscribe((res: any) => {
			this.CarregarNotas();
		})
	}

	limparCampo() {
		this.formNotas.get('userName').setValue('');
	}

}
