import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ApiRequestService } from '../../../services/api-request.service';
import { Config } from '../../../utils/Config';


@Component({
  selector: 'app-minhas-aulas-add-arquivo',
  templateUrl: './minhas-aulas-add-arquivo.component.html',
  styleUrls: ['./minhas-aulas-add-arquivo.component.scss']
})
export class MinhasAulasAddArquivoComponent {

	config = Config;
	adicionando:boolean = false;
	
	arquivoSel:any = [];
	arquivosNomes: string = '';
	type:any;

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<MinhasAulasAddArquivoComponent>,
		@Inject(MAT_DIALOG_DATA) public data:any
	) {
	}

	SelecionarArquivo(fileEvent:any){
		const files = fileEvent.target.files;

		if (files.length > 0){
			this.arquivoSel = Array.from(files);
			this.arquivosNomes = this.arquivoSel.map(file => file.name).join(', ');

		}else{
			alert("Selecione ao menos um arquivo válido");
		}

	}

	Close(): void {
		this.dialogRef.close();
	}

	Enviar(){

		if (this.arquivoSel.length === 0) {
            alert("Selecione ao menos um arquivo");
            return;
        }

		if(!this.type){
			alert("Selecione o tipo de arquivo");
			return;
		}


		this.adicionando = true;

		this.arquivoSel.forEach((file, index)=>{
			this.api.UploadPosS3(file).subscribe(
				(res: any) => {
					this.SalvarArquivo(res.file, file.name);
					if(index === this.arquivoSel.length -1){
						this.adicionando = false;
					}
				},
				error => {
					this.adicionando = false;
					console.error(error);
					alert("Erro ao enviar o arquivo: " + file.name);
				}
			);
		});

	}

	SalvarArquivo(dataFile:any, fileName: string){

		let data = {
			url: String(dataFile),
			name: fileName,
			type: this.type
		}

		this.api.Post("content/"+this.data.aula.id+"/file", data).subscribe(
			(res:any) => {
				this.dialogRef.close(res);
			},
			error => {
				this.adicionando = false;
				console.log(error);
				alert("Erro ao adicionar arquivo: " + fileName);
			})
	}

}
