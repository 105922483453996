import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiRequestService } from 'src/app/services/api-request.service';

@Component({
  selector: 'app-notas-edit',
  templateUrl: './notas-edit.component.html',
})
export class NotasEditComponent implements OnInit {
  nota_id: number;
  editNotaForm: FormGroup;
  

  saving = false;

  constructor(
    private api: ApiRequestService,
    public dialogRef: MatDialogRef<NotasEditComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    console.log("Elemento recebido com sucesso", data);
    this.nota_id = Number(data.nota_id)
    this.editNotaForm = this.formBuilder.group({
      nota: [data.nota, [Validators.required, Validators.min(0), Validators.max(10)]]
    });
  }

  ngOnInit() {}

  alterarNota():void{
    this.saving = true;

    const notaValue = this.editNotaForm.get('nota').value;
    const payload = {
      notaId: this.nota_id,
      nota: notaValue,
    };


    this.api.Post(`updateNota`, payload).subscribe(
      res => {
        this.dialogRef.close(res);
        this.saving = false; 
      },
      err => {
        console.error(err);
        this.saving = false; 
      }
    );
  }


  Close(): void {
    this.dialogRef.close();
  }

}
