import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { ApiRequestService } from "../../../services/api-request.service";
import { PageEvent } from '@angular/material/paginator';
import { ExcelServicesService } from '../../../services/excel-services.service';

@Component({
  selector: 'app-report-users',
  templateUrl: './report-users.component.html',
  styleUrls: ['./report-users.component.scss']
})
export class ReportUsersComponent implements OnInit {

	table:any;
	data:any;
  columnsToDisplay: string[] = [
    "id",
    "nome",
    "email",
    "cpf",
    "celular",
    "genero",
    "cidade",
    "curso",
    "turma",
    "disciplina",
    "origem",
    "status",
    "acoes"
  ];

  turmas: any[] = [];
  cursos: any[] = [];
  disciplinas: any[] = [];

  status: any[] = [
    {
      name: "Ativo",
      value: "active"
    },
    {
      name: "Cancelado",
      value: "canceled"
    },
    {
      name: "Acesso livre",
      value: "trial"
    }
  ];

  pesquisa: any = {
    name: '',
    classes: [],
    courses: [],
    disciplines: [],
    status: [],
  }

	constructor(
		public apiRequest: ApiRequestService,
		private route: ActivatedRoute,
		private router: Router,
		private excelService:ExcelServicesService
	) { }

	ngOnInit() {
		this.route.params.subscribe((p:any)=>{
			this.table = 'Alunos';
      this.GetClasses();
      this.GetCourses();
      this.GetDisciplines();
			this.LoadReport();
		});
	}

  GetClasses() {
    this.apiRequest.Get("products").subscribe((response: any[]) => {
      if (response.length > 0) {
        this.turmas = response.map((value) => {
          return {
            id: value.id,
            name: value.name
          }
        });
      }
    });
  }

  GetCourses() {
    this.apiRequest.Get("cursos").subscribe((response: any[]) => {
      if (response.length > 0) {
        this.cursos = response.map((value) => {
          return {
            id: value.id,
            name: value.name
          }
        });
      }
    })
  }

  GetDisciplines() {
    this.apiRequest.Get("journey/index").subscribe((response: any[]) => {
      this.disciplinas = response;
    })
  }

	LoadReport(pag:number = 0, pageSize:number = 25){
    
    this.apiRequest.Post("report/users?page=" + (pag + 1) + "&pagesize=" + pageSize, this.pesquisa).subscribe((res: any) => {
      res.data = this.formatStudentsData(res.data);
      
      this.data = res;

      if (this.data.total > 0) {
        this.columnsToDisplay = Object.keys(this.data.data[0]);

			}
		});

	}

  openDetailsCode(code) {
    this.router.navigate([`/report/voucher/${code}`])
  }

	public ChangePage(event?:PageEvent){
		this.LoadReport(event.pageIndex, event.pageSize);
	}

	exportAsXLSX():void {
		this.apiRequest.Post("report/users?page=1&pagesize=9999999", {}).subscribe((res:any)=>{

      res.data = this.formatStudentsData(res.data);

			this.excelService.exportAsExcelFile(res.data, this.table);
		});
  }

  exportStudentContentTimeAsXLSX(id: number, name: string): void {
    this.apiRequest.Get(`report/users/content/${id}`).subscribe((res:any) => {
      const body = this.formatContentTimeUserData(res);
      
      this.excelService.exportAsExcelFile(body, 'Tempo das aulas - ' + name);
    });    
  }

  exportStudentNotesAsXLSX(id: number, name: string): void {
    this.apiRequest.Get(`report/users/notes/${id}`).subscribe((res:any) => {
      const body = this.formatNotesUserData(res);
      
      this.excelService.exportAsExcelFile(body, 'Notas - ' + name);
    });    
  }

  formatStudentsData(data) {
    data = data.map((item) => ({
        "id": item.user_id,
        "nome": item.name,
        "email": item.email,
        "cpf": this.formatCPF(item.cpf),
        "celular": this.formatPhoneNumber(item.phone),
        "genero": this.formatGender(item.gender),
        "cidade": item.city,
        "curso": item.course_name,
        "turma": item.product_name,
        "disciplina": item.journey_name,
        "origem": this.formatOrigin(item.origin),
        "status": this.formatStatus(item.status),
        "acoes": ""
    }));

    return data;
  }

  formatContentTimeUserData(data) {
    data = data.map((item) => ({
      "Curso": item.course_name,
      "Turma": item.class_name,
      "Titulo_Aula": item.content_title,
      "Horas_assistidas": item.current_time_hms,
      "Horas_totais": item.total_time_hms,
      "Ultimo_Acesso": item.formatted_last_access
    }));

    return data;
  }

  formatNotesUserData(data) {
    data = data.map((item) => ({
      "Curso": item.course_name,
      "Turma": item.class_name,
      "Disciplina": item.discipline_name,
      "Nota": item.note
    }));

    return data;
  }

  formatPhoneNumber(phone: string) {
   if (!!phone) {
    phone = phone.replace(/\D/g, "");

    if (phone.length === 11) {
      return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } 
    
    if (phone.length === 10) {
      return phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    } 
    
    if (phone.length === 9) {
      return phone.replace(/(\d{5})(\d{4})/, "$1-$2");
    } 
    
    if (phone.length === 8) {
      return phone.replace(/(\d{4})(\d{4})/, "$1-$2");
    } 
   }
      
    return phone;
  }

  formatCPF(cpf: string) {
    if (!!cpf) {
      cpf = cpf.replace(/\D/g, "");

      if (cpf.length === 11) {
          return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      } 
    }
    
    return cpf;
  }

  formatGender(gender: string) {
    if (gender == "male")
      return "Homem"
    if (gender == "female")
      return "Mulher"

    return gender;
  }

  formatStatus(status: string) {
    if (status == "active")
      return "Ativo"
    if (status == "canceled")
      return "Cancelado"
    if (status == "trial")
      return "Acesso livre"

    return status;
  }

  formatOrigin(origin: string) {
    if (origin == "admin")
      return "Administrador"
    if (origin == "site")
      return "Organico"

    return origin;
  }
}
