import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { ApiRequestService } from "../../../services/api-request.service";
import { PageEvent } from '@angular/material/paginator';
import { ExcelServicesService } from '../../../services/excel-services.service';

@Component({
  selector: 'app-report-voucher',
  templateUrl: './report-voucher.component.html',
  styleUrls: ['./report-voucher.component.scss']
})
export class ReportVoucherComponent implements OnInit {

	table:any;
	data:any;
  columnsToDisplay: string[] = [];
  selectedDateStart: Date = null;
  selectedDateEnd:Date = null;

	constructor(
		public apiRequest: ApiRequestService,
		private route: ActivatedRoute,
		private router: Router,
		private excelService:ExcelServicesService
	) { }

	ngOnInit() {

		this.route.params.subscribe((p:any)=>{
			this.table = 'voucher';
			this.LoadReport();
		});

	}

	LoadReport(pag:number = 0, pageSize:number = 25){

    this.apiRequest.Post("report/voucher?page=" + (pag + 1) + "&pagesize=" + pageSize, { start: this.selectedDateStart, end: this.selectedDateEnd }).subscribe((res: any) => {

      res.data = this.formatData(res.data);

      this.data = res;

      if (this.data.total > 0) {
        this.columnsToDisplay = Object.keys(this.data.data[0]);

			}

		});

	}

  openDetailsCode(code) {
    this.router.navigate([`/report/voucher/${code}`])
  }

	public ChangePage(event?:PageEvent){
		this.LoadReport(event.pageIndex, event.pageSize);
	}

	exportAsXLSX():void {
		this.apiRequest.Post("report/voucher?page=1&pagesize=999999", { start: this.selectedDateStart, end: this.selectedDateEnd }).subscribe((res:any)=>{

      res.data = this.formatData(res.data);

			this.excelService.exportAsExcelFile(res.data, this.table);
		});
  }

  formatData(data) {

    data = data.map((item) => ({
        'Id': item.id,
        'Código do Voucher': item.code,
        'Qtd. Inicial': item.use_limit,
        'Qtd. Utilizada': item.use_count,
        'Qtd. Disponível': (item.use_limit - item.use_count),
        'Tipo': (item.type == 'discount' ? 'DESCONTO' : (item.type == 'free_days' ? 'DIAS GRÁTIS' : '-' )),
        'Desconto / Qtd. Dias': (item.type == 'discount' ? item.discount + '%' : item.duration + (item.period == 'D' ? ' Dias' : (item.period == 'M' ? ' MESES' : ''))),
        'Criado por': (item.user ? item.user.name : '-'),
        'Turma': (item.product ? item.product.name : 'Todas as Turmas'),
        'Criado em': item.created
    }));

    return data;

  }

}
