import { AbstractControl, Validators } from '@angular/forms';

export class GenericValidator {

  //Valida se tem sobrenome
  static isValidName() {
    return (control: AbstractControl): Validators => {
      const name = control.value as string;

      const hasNumber = /\d/.test(name);
      if (hasNumber) {
        return { nameNotValid: true, reason: 'containsNumber' };
      }

      const splitedName = name.split(' ');

      if (splitedName.length > 0) {
        if (splitedName.length == 1)
        return {nameNotValid: true};

      if (splitedName[1].length == 0)
        return {nameNotValid: true};
      }

      return null;
    };
  }
}
