import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'Sec2MinSec'
})
export class Sec2MinSecPipe implements PipeTransform {

	transform(value: any, args?: any): any {

		let s = Number(value);
		let hours = Math.floor(s / 3600);
		let minutes = Math.floor((s % 3600) / 60);
		let seconds = s % 60;
	
		let result = 
			(hours > 0 ? hours.toString().padStart(2, '0') + ':' : '') + 
			minutes.toString().padStart(2, '0') + ':' + 
			seconds.toString().padStart(2, '0');
	
		return result;
	}

}
