import { Component, Input, OnInit } from '@angular/core';
import { Config } from "../../utils/Config";
import { ApiRequestService } from "../../services/api-request.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AcoesEspeciais } from '../../utils/AcoesEspeciais';
import { quillConfig } from 'src/app/utils/QuillConfig';

@Component({
	selector: 'app-form-fields',
	templateUrl: './form-fields.component.html',
	styleUrls: ['./form-fields.component.scss']
})
export class FormFieldsComponent implements OnInit {

	@Input() field:any;
	@Input() data:any;
	@Input() dataForm:any;
	@Input() selects:any;

	config:any = Config;
	quilConfig = quillConfig;

	constructor(
		public apiRequest: ApiRequestService,
		private http: HttpClient
	) { }

	ngOnInit() {
		if(this.field.tipo=="select"){
			this.data[this.field.campo] = this.data[this.field.campo] === undefined ? [] : parseInt(this.data[this.field.campo]);
		}
	}

	//UPLOAD DE ARQUIVOS
	onFileChanged(event, campo) {
		let f:File = event.target.files[0];

		this.apiRequest.Upload(f).subscribe((ret:any)=>{
			this.data[campo] = ret.file;
		});
	}

	//UPLOAD DE ARQUIVOS
	onFileChangedPosS3(event, campo) {
		let f:File = event.target.files[0];

		this.apiRequest.UploadPosS3(f).subscribe((ret:any)=>{
			this.data[campo] = ret.file;
		});
	}
	

	//UPLOAD DE ARQUIVOS
	onFileChangedS3(event, field) {
		let f:File = event.target.files[0];

		this.apiRequest.UploadS3(f, field.folder, true).then((ret:any)=>{
			console.log(ret);
			this.data[field.campo] = ret.url;
		});
	}


	AcaoEspecial(){

		let acao:AcoesEspeciais = new AcoesEspeciais(this.apiRequest, this.http);
		acao.AcaoEspecial(this.field, this.data, this.dataForm);

		/*
		if(this.field.onChange!=undefined){
			if(this.field.onChange.tipo=="RetornarLatLng"){
				this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address="+this.data.nome+"&key=AIzaSyC37c-g2AXnDGNCEALL5G2uyLAQxxhRF1Q").subscribe((ret:any)=>{
					console.log(ret);
					this.data.lat = ret.results[0].geometry.location.lat;
					this.data.lng = ret.results[0].geometry.location.lng;
				});
			}
		}
		*/

	}

}
