import { Component, Input, OnInit } from '@angular/core';
import { Config } from "./Config";
import { ApiRequestService } from "../services/api-request.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';

export class AcoesEspeciais {

	field:any;
	data:any;
	dataForm:any;
	
	config:any = Config;

	constructor(
		public apiRequest: ApiRequestService,
		public http: HttpClient
	) { }


	public AcaoEspecial(_field, _data, _dataForm){

		this.field = _field;
		this.data = _data;
		this.dataForm = _dataForm;

		if(this.field.campo=="slug"){

			let slug = this.dataForm.slug
			.normalize("NFD") // Normaliza para decomposição de caracteres Unicode
			.replace(/[\u0300-\u036f]/g, "") // Remove acentuação
			
			slug = slug.replace(/\s+-\s+/g, '-') // Substitui espaço-hífen-espaço por hífen

			slug = slug.replace(/\s+/g, '-') // Substitui sequências de espaços por hífens
			
			slug = slug.toLowerCase() // Transforma todas as letras em minúsculas

			this.dataForm.slug = slug;
		}
		
		if(this.field.onChange!=undefined){
			switch (this.field.onChange.tipo) {
				case "RetornarLatLng":
					this.pegarLatLngGoogleMaps();
					break;
				case "BuscarTransportadora":
					this.BuscarTransportadora();
					break;
				case "BuscarEndereco":
					this.BuscarEndereco();
					break;
				case "BuscarProduto":
					this.BuscarProduto();
					break;
				case "SomarValorTotal":
					this.SomarValorTotal();
					break;
				default:
					console.warn("Tipo não encontrado: "+this.field.onChange.tipo);
					break;
			}
		}
		

	}

	pegarLatLngGoogleMaps(){
		this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address="+this.data.nome+"&key=AIzaSyC37c-g2AXnDGNCEALL5G2uyLAQxxhRF1Q").subscribe((ret:any)=>{
				if(ret.results.length>0){
					this.data.lat = ret.results[0].geometry.location.lat;
					this.data.lng = ret.results[0].geometry.location.lng;
				} else {
					this.data.lat = this.data.lng = "";
				}
			});
	}

	BuscarTransportadora(){
		this.apiRequest.Get("logistica/nf/transportadora/"+this.data.transporte).subscribe((ret:any)=>{
			//console.log(ret);
			this.data.modal = ret.modal;
		});
	}

	BuscarEndereco(){
		this.apiRequest.Get("logistica/nf/endereco/"+this.data.destino).subscribe((ret:any)=>{
			//console.log(ret);
			this.data.aos_cuidados = ret.aos_cuidados;
			this.data.informacoes_complementares = ret.informacoes_complementares;
		});
	}

	BuscarProduto(){
		console.log(this.data);
		if(!isNaN(this.data.produto)){
			this.apiRequest.Get("logistica/nf/equipamento/"+this.data.produto).subscribe((ret:any)=>{
				this.data.valor = ret.nf_valor_unitario;
				if(this.data.qtd=="") this.data.qtd = 1;
				this.SomarValorTotal();
			});
		}
	}

	SomarValorTotal(){
		let total = 0;
		console.log(this.dataForm.produtos);
		for(let i:number = 0; i<this.dataForm.produtos.length; i++){
			console.log(this.dataForm.produtos[i]);
			let t = this.dataForm.produtos[i].qtd * this.dataForm.produtos[i].valor;
			total += t;
			this.dataForm.produtos[i].valor_total = t;
		}

		this.dataForm.valor_total = total;
	}

}
