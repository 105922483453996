import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Config } from "../utils/Config";
import { User } from "../utils/User";
import { environment } from "../../environments/environment";
import { ProdutoSelecionado } from "../utils/ProdutoSelecionado";
import * as AWS from "aws-sdk";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiRequestService {
  usuario: User = new User();

  constructor(private http: HttpClient) {}

  GetHeaders(): object {
    let h: HttpHeaders;

    this.usuario.Load();

    if (this.usuario.CheckLogin()) {
      h = new HttpHeaders({
        Authorization: "Bearer " + this.usuario.data.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      });
    } else {
      h = new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
    }

    return { headers: h };
  }

  Login(data) {
    return this.http.post(
      environment.apiUrl + "login",
      JSON.stringify(data),
      this.GetHeaders()
    );
  }

  Get(url) {
    if (ProdutoSelecionado.id != 0) {
      if (url.indexOf("?") !== -1) {
        url = url + "&product_id=" + ProdutoSelecionado.id;
      } else {
        url = url + "?product_id=" + ProdutoSelecionado.id;
      }
    }

    return this.http.get(environment.apiUrl + url, this.GetHeaders());
  }

  Post(url, data) {
    return this.http.post(
      environment.apiUrl + url,
      JSON.stringify(data),
      this.GetHeaders()
    );
  }

  Put(url, data) {
    console.log(data);
    return this.http.put(
      environment.apiUrl + url,
      JSON.stringify(data),
      this.GetHeaders()
    );
  }

  Delete(url) {
    return this.http.delete(environment.apiUrl + url, this.GetHeaders());
  }

  Upload(file) {
    let h = new HttpHeaders({
      Authorization: "Bearer " + this.usuario.data.token,
      Accept: "application/json",
    });

    let uploadData = new FormData();
    uploadData.append("file", file);

    return this.http.post(environment.apiUrl + "upload", uploadData, {
      headers: h,
    });
  }

  GetCep(zipCode: string) {
    zipCode = zipCode.replace(".", "");
    zipCode = zipCode.replace("-", "");

    return this.http.get("https://viacep.com.br/ws/" + zipCode + "/json/");
  }

  UploadPosS3(file) {
    let h = new HttpHeaders({
      Authorization: "Bearer " + this.usuario.data.token,
      Accept: "multipart/form-data",
    });

    let uploadData = new FormData();
    uploadData.append("file", file);

    return this.http.post(environment.apiUrl + "upload/pos/s3", uploadData, {
      headers: h,
    });
  }

  DeleteFromPosS3(fileUrl): Observable<any> {
    let h = new HttpHeaders({
      Authorization: "Bearer " + this.usuario.data.token,
      Accept: "application/json",
    });

    const data = {
      headers: h,
      body: { file_path: fileUrl }
    };

    return this.http.delete(environment.apiUrl + "upload/pos/s3", data);
  }


  UploadS3(file: any, folder?: string, ignoreUser: boolean = false) {
    if (!folder) {
      folder = "";
    }

    if (!environment.production) {
      folder = "dev/" + folder;
    }

    if (!ignoreUser) {
      folder = folder + this.usuario.data.user.id + "/";
    }

    const AWSService = AWS;

    AWS.config.update({
      region: environment.awsS3.region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: environment.awsS3.IdentityPoolId,
      }),
    });

    const s3 = new AWSService.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: environment.awsS3.bucketName },
    });

    return new Promise((resolve, reject) => {
      let ext = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
      let filename = Date.now().toString() + "." + ext;

      s3.upload(
        {
          Key: folder + filename,
          Bucket: environment.awsS3.bucketName,
          Body: file,
          ACL: "public-read",
          StorageClass: "INTELLIGENT_TIERING",
          Metadata: {
            "Cache-Control": "max-age=31557600",
          },
        },
        function (err, data) {
          if (data) {
            let result = {
              filename: filename,
              folder: folder,
              url: environment.awsS3.url + folder + "{size}/" + filename,
              data: data,
            };
            resolve(result);
          }
          if (err) {
            alert("Erro ao anexar arquivo");
            console.error(err);
          }
        }
      );
    });
  }

  DeleteS3(file: any) {
    const AWSService = AWS;

    AWS.config.update({
      region: environment.awsS3.region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: environment.awsS3.IdentityPoolId,
      }),
    });

    const s3 = new AWSService.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: environment.awsS3.bucketName },
    });

    return new Promise((resolve, reject) => {
      s3.deleteObject(
        {
          Key: file,
          Bucket: environment.awsS3.bucketName,
        },
        function (err, data) {
          if (data) {
            resolve(data);
          }
          if (err) {
            alert("Erro ao remover arquivo");
            console.error(err);
          }
        }
      );
    });
  }
}
