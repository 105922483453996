import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiRequestService } from '../../../services/api-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material';
import { QuestionFormDialogComponent } from '../../../dialogs/question-form/question-form-dialog.component';

@Component({
  selector: 'app-quiz-form',
  templateUrl: './quiz-form.component.html',
  styleUrls: ['./quiz-form.component.scss']
})
export class QuizFormComponent implements OnInit {

	@ViewChild(MatPaginator) paginator: MatPaginator;

	formCadastro:FormGroup= new FormGroup({
		codigo: new FormControl('', Validators.required),
		nome: new FormControl('', Validators.required),
		curso_ids: new FormControl([], Validators.required),
		pesquisa: new FormControl(''),
		computarNota: new FormControl(null),
		exibeListaQuestoes: new FormControl(false),
	});

	cursos: any[] = [];

	nome: string = "";

	colunas: string[] = ['question', 'keywords', 'actions'];

	questoes: any = [];

	questoesSelecionadas: any = [];

	idEdicao: number = 0;

	constructor(
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService,
	) {
	}

	ngAfterViewInit() {
		this.questoes.paginator = this.paginator;
	}

	ngOnInit() {
		this.buscarCursos();
		this.buscaQuestoes();
		
		this.route.params.subscribe(value => {
			if(value.id != undefined){
				this.idEdicao = value.id;

				this.carregarDados(value.id);		
			}
		});
		
	}

	buscarCursos() {
		this.api.Get("cursos").subscribe((response: any[]) => {
			if (!!response) {
				response.forEach((value) => {
					this.cursos.push({
						id: value.id,
						value: value.name
					});
				});
			}
			
		});
	}

	buscaQuestoes(pesquisa = false) {
		this.api.Get(`question?page=${this.questoes.current_page + 1}&pageSize=${this.questoes.per_page}&q=${this.formCadastro.get('pesquisa').value}`).subscribe((response: any) => {
			if (!!response && !!response.data) {
				this.questoes = response;	
				
				if (pesquisa) {
					this.paginator.firstPage();
				}
			}
		});
	}

	carregarDados(id: number){
		this.api.Get("quiz/" + id).subscribe((response: any)=>{

			this.formCadastro.get('nome').setValue(response.name);
			this.formCadastro.get('codigo').setValue(response.code);
			this.formCadastro.get('computarNota').setValue(response.compute_note);

			if (response.questions.length > 0) {
				this.formCadastro.get('exibeListaQuestoes').setValue(true);
				const questoes = response.questions.map((value) => {
					return {
						id: value.question_id,
						question: value.question,
						keywords: value.keywords,
					}
				});

				this.questoesSelecionadas = questoes;
			}
		}, error=> {
			alert("Erro ao carregar dados. Contate o administrador");
		});

		this.api.Get("quiz/busca-cursos/" + id).subscribe((response: any[]) => {
			this.formCadastro.get('curso_ids').setValue(response.map(value => value.id));
		}, error=> {
			alert("Erro ao carregar dados. Contate o administrador");
		});
	}

	adicionarQuestao(item: any) {
		const existeQuestao = this.questoesSelecionadas.find((value) => value.id == item.id);

		if (!!existeQuestao) {
			this.notifications.create("Erro", "A questão selecionada já foi adicionada", NotificationType.Alert, {
				timeOut: 3000
			});
		} else {
			this.questoesSelecionadas.push(item);
			this.questoesSelecionadas = [...this.questoesSelecionadas];

			this.notifications.create("Sucesso", "Questão adicionada com sucesso", NotificationType.Success, {
				timeOut: 2000
			});
		}
	}

	removerQuestao(id: any) {
		const existeQuestao = this.questoesSelecionadas.find((value) => value.id == id);

		if (!!existeQuestao) {
			const indiceQuestao = this.questoesSelecionadas.findIndex((value) => value.id == id);

			if (this.idEdicao > 0) {
				this.api.Delete(`quiz/${this.idEdicao}/question/${id}`).toPromise();
			}

			this.questoesSelecionadas.splice(indiceQuestao, 1);
			this.questoesSelecionadas = [...this.questoesSelecionadas];

			this.notifications.create("Sucesso", "Questão removida com sucesso", NotificationType.Success, {
				timeOut: 2000
			});
		} else {
			this.notifications.create("Erro", "Questão não encontrada", NotificationType.Error, {
				timeOut: 3000
			});
		}
	}

	mudarPagina(evento: PageEvent) {
		this.questoes.current_page = evento.pageIndex;
		this.questoes.per_page = evento.pageSize;
		
		this.buscaQuestoes();
	}

	salvar(){
		let payload = {
			code: this.formCadastro.get('codigo').value,
			name: this.formCadastro.get('nome').value,
			courses: this.formCadastro.get('curso_ids').value,
			compute_note: this.formCadastro.get('computarNota').value,
			questions: []
		};

		payload.questions = this.questoesSelecionadas.map((value) => value.id);

		if (this.idEdicao == 0) {
			this.api.Post('quiz', payload).subscribe(() => {
				this.notifications.create("Sucesso", "Prova salva com sucesso", NotificationType.Success, {
					timeOut: 1000
				});

				setTimeout(() => {
					this.router.navigate(['prova']);
				}, 1500);
			});
		} else {
			this.api.Post('quiz/' + this.idEdicao, payload).subscribe((response) => {
				this.notifications.create("Sucesso", "Prova editada com sucesso", NotificationType.Success, {
					timeOut: 1000
				});

				setTimeout(() => {
					this.router.navigate(['prova']);
				}, 1500);
			});
		}
	}

	voltar(): void {
		this.router.navigate(['prova']);
	}

	abrirModalQuestao() {
		const dialogRef = this.dialog.open(QuestionFormDialogComponent, {
			width: "80%",
		  });
	  
		  dialogRef.afterClosed().subscribe((res) => {
			if (res != undefined) {
				this.questoesSelecionadas.unshift({
					id: res.id,
					question: res.question,
					keywords: res.keywords,
				});

				this.questoesSelecionadas = [...this.questoesSelecionadas];

				this.buscaQuestoes();
			}
		  });
	}

}
