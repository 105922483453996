import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from 'src/app/services/api-request.service';
import { MatDialog } from '@angular/material';
import { ConfirmDeleteComponent } from 'src/app/dialogs/confirm-delete/confirm-delete.component';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface Curso {
  id: number;
  name: string;
  slug: string;
  image: string;
  category: string;
  seal: string;
  sigla: string;
  created_at: string;
  updated_at: string;
}

export interface CursoPagination {
  current_page: number;
  data: Curso[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string | null;
  path: string;
  per_page: number;
  prev_page_url: string | null;
  to: number;
  total: number;
}

export interface Paginacao {
  per_page: number;
  total: number;
}

@Component({
  selector: 'app-listar-cursos',
  templateUrl: './listar-cursos.component.html',
  styleUrls: ['./listar-cursos.component.scss']
})
export class ListarCursosComponent implements OnInit {
  cursos: Curso[] = [];
  columnsToDisplay = ["name", "acoes"];
  isLoading = false;
  pesquisa: string = '';
  private pesquisaSubject: Subject<string> = new Subject<string>();
  pageEvent: any = { length: 0, pageIndex: 0, pageSize: 50, previousPageIndex: 0 };
  dadosPaginacao: any = {};
  busca = {
    pageSize: 50,
    page: ''
  }

	constructor(
		private router: Router,
    private api: ApiRequestService,
    public dialog: MatDialog,
    private notifications: NotificationsService,
	) { }

	ngOnInit() {
    this.pesquisaSubject.pipe(debounceTime(300)).subscribe(() => {
      this.filtrarCursos();
    });
  
    this.getCursos();
	}

  getCursos() {
    const url = 'cursos/';
    this.isLoading = true;

    const data = {
      name: '',
      pageSize: this.busca.pageSize,
      page: this.busca.page
    }

    if (this.pesquisa) {
      data.name = this.pesquisa;
    }

    this.api.Post(url, data).subscribe(({
      next: (res: CursoPagination) => {
        this.cursos = res.data;
        this.dadosPaginacao.per_page = res.per_page;
        this.dadosPaginacao.total = res.total;
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      }
    }));
  }

  filtrarCursos() {
    this.getCursos();
  }

  pesquisaChanged() {
    this.pesquisaSubject.next(this.pesquisa);
  }

  deleteCurso(id: number) {
    const url = `cursos/delete/${id}`;

    this.api.Delete(url).subscribe(({
      next: (res) => {
        this.notifications.create("Sucesso!", "Curso deletado com sucesso!", NotificationType.Success, { timeOut: 2500 } );
        this.getCursos();
      },
      error: (err) => {
        if(err.error.error){
          this.notifications.create("Erro!", err.error.error, NotificationType.Error, { timeOut: 2500 } );
        }else{
          this.notifications.create("Erro!", "Não foi possível excluir curso", NotificationType.Error, { timeOut: 2500 } );
        }
      }
    }));
  }

  openModalConfirmDelete(id: number) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        text: "Tem certeza que deseja excluir este curso?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Excluir') {
        this.deleteCurso(id);
      }
    });
  }
}