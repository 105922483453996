import { Component, OnInit } from '@angular/core';
import { MatDialog, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ConfirmDeleteComponent } from 'src/app/dialogs/confirm-delete/confirm-delete.component';
import { ApiRequestService } from 'src/app/services/api-request.service';


export interface Turma {
  id: number;
  name: string;
  active: any;
  slug: string;
  image: string;
  sigla: string;
  created_at: string;
  updated_at: string;
}

export interface TurmaPagination {
  current_page: number;
  data: Turma[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string | null;
  path: string;
  per_page: number;
  prev_page_url: string | null;
  to: number;
  total: number;
}


@Component({
  selector: 'app-turmas-list',
  templateUrl: './turmas-list.component.html',
  styleUrls: ['./turmas-list.component.scss']
})
export class TurmasListComponent implements OnInit {
  columnsToDisplay =  ["slug", "name", "active", "acoes"];
  turmas: Turma[] = [];
  isLoading = false;
  pesquisa: string = '';
  ativo: boolean = true;
  dadosPaginacao: any = {};
  busca = {
    pageSize: 25,
    page: 0
  }

  constructor(
    private router: Router,
    private api: ApiRequestService,
    public dialog: MatDialog,
    private notifications: NotificationsService,
  ) { }

  ngOnInit() {
    this.getTurmas();
  }

  async getTurmas(){
    const url = 'turmas/';
    this.isLoading = true;

    const data = {
      name: '',
      ativo: this.ativo,
      pageSize: this.busca.pageSize,
      page: this.busca.page
    }

    if (this.pesquisa) {
      data.name = this.pesquisa;
    }

    this.api.Post(url, data).subscribe(({
      next: (res: TurmaPagination) => {
        this.turmas = res.data;
        this.dadosPaginacao.size = res.per_page;
        this.dadosPaginacao.length = res.total;
        this.isLoading = false;

        this.turmas.forEach(turma=>{
          turma.active = turma.active == 1 ? "Sim" : "Não";
        })
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      }
    }));
  }

  atualizarPaginacao(event?:PageEvent){
    this.busca.pageSize = event.pageSize;
    this.busca.page = event.pageIndex + 1; 
    this.dadosPaginacao.pageIndex = event.pageIndex; 
    this.getTurmas();
	}

  deleteTurma(id: number) {
    const url = `turmas/${id}`;

    this.api.Delete(url).subscribe(({
      next: (res) => {
        this.notifications.create("Sucesso!", "Turma deletada com sucesso!", NotificationType.Success, { timeOut: 2500 } );
        this.getTurmas();
      },
      error: (err) => {
        console.log(err);
      }
    }));
  }


  openModalConfirmDelete(id: number) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        text: "Tem certeza que deseja excluir esta turma?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Excluir') {
        this.deleteTurma(id);
      }
    });
  }


  duplicateTurma(id: number) {
    const url = `turmas/duplicate/${id}`;

    this.api.Get(url).subscribe(({
      next: (res) => {
        this.notifications.create("Sucesso!", "Turma duplicada com sucesso!", NotificationType.Success, { timeOut: 2500 } );
        this.getTurmas();
      },
      error: (err) => {
        console.log(err);
      }
    }));
  }
}
