import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatChipsModule} from '@angular/material/chips';
import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatRippleModule} from '@angular/material/core';
import {MatSortModule} from '@angular/material/sort';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatTreeModule, MatIconModule } from '@angular/material';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { CurrencyMaskModule } from "ng2-currency-mask";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { EstruturaComponent } from './pages/estrutura/estrutura.component';
import { LoginComponent } from './pages/login/login.component';
import { ListarComponent } from './pages/crud/listar/listar.component';
import { FormComponent } from './pages/crud/form/form.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatSliderModule, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS} from '@angular/material';

import { EditorModule } from '@tinymce/tinymce-angular';
import { SimpleNotificationsModule } from 'angular2-notifications';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {FullCalendarModule} from "ng-fullcalendar";

//LoadSpinner
import { NgxLoadingModule } from 'ngx-loading';
import {NgxMaskModule} from 'ngx-mask'

import { FormFieldsComponent } from './component/form-fields/form-fields.component';
import { MinhasAulasComponent } from './pages/minhas-aulas/minhas-aulas.component';
import { MinhasAulasFormComponent } from './pages/minhas-aulas/minhas-aulas-form/minhas-aulas-form.component';
import { MinhasAulasAgendamentoComponent } from './pages/minhas-aulas/minhas-aulas-agendamento/minhas-aulas-agendamento.component';
import { MinhasAulasAddVideoComponent } from './pages/minhas-aulas/minhas-aulas-add-video/minhas-aulas-add-video.component';
import { MinhasAulasVideoComponent } from './pages/minhas-aulas/minhas-aulas-video/minhas-aulas-video.component';
import { MinhasAulasVideoAddRevisaoComponent } from './pages/minhas-aulas/minhas-aulas-video-add-revisao/minhas-aulas-video-add-revisao.component';
import { MinhasAulasAddArquivoComponent } from './pages/minhas-aulas/minhas-aulas-add-arquivo/minhas-aulas-add-arquivo.component';
import { CalendarioComponent } from './component/calendario/calendario.component';
import { CalendarioExibirComponent } from './pages/calendario-exibir/calendario-exibir.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { UsuariosPermissoesComponent } from './pages/usuarios/usuarios-permissoes/usuarios-permissoes.component';
import { UsuariosInstrutoresComponent } from './pages/usuarios/usuarios-instrutores/usuarios-instrutores.component';
import { UsuariosFormComponent } from './pages/usuarios/usuarios-form/usuarios-form.component';
import { StatusAulaComponent } from './pages/minhas-aulas/status-aula/status-aula.component';
import { FaqComponent } from './pages/faq/faq.component';
import { MinhasAulasTopicosComponent } from './pages/minhas-aulas/minhas-aulas-topicos/minhas-aulas-topicos.component';
import { MinhasAulasPlaylistsComponent } from './pages/minhas-aulas/minhas-aulas-playlists/minhas-aulas-playlists.component';
import { MinhasAulasVideoAddSumarioComponent } from './pages/minhas-aulas/minhas-aulas-video-add-sumario/minhas-aulas-video-add-sumario.component';

//import { FormFieldsDirective } from './directive/form-fields.directive';

import { StripTagsPipe } from './pipes/strip-tags.pipe';
import { FormatPhonePipe } from './pipes/format-phone.pipe';
import { CheckImageUrlPipe } from './pipes/check-image-url.pipe';
import { Sec2MinSecPipe } from './pipes/sec2-min-sec.pipe';
import { UsuariosTrocarSenhaComponent } from './pages/usuarios/usuarios-trocar-senha/usuarios-trocar-senha.component';
import { KeepHtmlPipe } from './keep-html.pipe';
import { DialogPadraoComponent } from './component/dialog-padrao/dialog-padrao.component';
import { SimulatorCasosListarComponent } from './pages/simulador/simulator-casos-listar/simulator-casos-listar.component';
import { SimulatorCasosFormComponent } from './pages/simulador/simulator-casos-form/simulator-casos-form.component';
import { CleanTextForURLPipe } from './pipes/clean-text-for-url.pipe';
import { SimulatorCasosFluxogramaComponent } from './pages/simulador/simulator-casos-fluxograma/simulator-casos-fluxograma.component';
import { SimulatorCasosFluxogramaEtapaModalComponent } from './pages/simulador/simulator-casos-fluxograma-etapa-modal/simulator-casos-fluxograma-etapa-modal.component';
import { Ng2PanZoomModule } from 'ng2-panzoom';
import { SimulatorCasosFluxogramaProtocoloModalComponent } from './pages/simulador/simulator-casos-fluxograma-protocolo-modal/simulator-casos-fluxograma-protocolo-modal.component';
import { MinhasAulasAtividadeComponent } from './pages/minhas-aulas/minhas-aulas-atividade/minhas-aulas-atividade.component';
import { SimulatorAnamnesisListarComponent } from './pages/simulador/simulator-anamnesis-listar/simulator-anamnesis-listar.component';
import { SimulatorAnamnesisFormComponent } from './pages/simulador/simulator-anamnesis-form/simulator-anamnesis-form.component';
import { SimulatorFeedbacksListarComponent } from './pages/simulador/simulator-feedbacks-listar/simulator-feedbacks-listar.component';
import { SimulatorFeedbacksFormComponent } from './pages/simulador/simulator-feedbacks-form/simulator-feedbacks-form.component';
import { SimulatorFeedbacksAddVideoComponent } from './pages/simulador/simulator-feedbacks-add-video/simulator-feedbacks-add-video.component';
import { QuestionListComponent } from './pages/questions/question-list/question-list.component';
import { QuestionFormComponent } from './pages/questions/question-form/question-form.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';

import { RouteReuseStrategy } from "@angular/router";
import { CustomRouteReuseStategy } from './utils/CustomRouteReuseStategy';
import { RegistrationCodeComponent } from './pages/media/registration-code/registration-code.component';
import { StudioBlockFormComponent } from './pages/studio/studio-block-form/studio-block-form.component';
import { StudioBlockInfoModalComponent } from './pages/studio/studio-block-info-modal/studio-block-info-modal.component';
import { TopicsListComponent } from './pages/topics/topics-list/topics-list.component';
import { TopicThumbComponent } from './pages/topics/topic-thumb/topic-thumb.component';

import { MomentJsPipe } from './pipes/moment-js.pipe';
import { InstructorsComponent } from './pages/instructors/instructors/instructors.component';
import { InstructorsFormComponent } from './pages/instructors/instructors-form/instructors-form.component';
import { ReportViewComponent } from './pages/reports/report-view/report-view.component';
import { ReportVoucherComponent } from './pages/reports/report-voucher/report-voucher.component';
import { ReportVoucherDetailsComponent } from './pages/reports/report-voucher-details/report-voucher-details.component';
import { UserSearchComponent } from './dialogs/user-search/user-search.component';
import { CheckUserInfoComponent } from './pages/contracts/check-user-info/check-user-info.component';
import { SearchStudioBlockComponent } from './dialogs/search-studio-block/search-studio-block.component';
import { InstructorsFormPageComponent } from './pages/instructors/instructors-form-page/instructors-form-page.component';
import { InstructorsFormDialogComponent } from './pages/instructors/instructors-form-dialog/instructors-form-dialog.component';
import { TopicsFilterPipePipe } from './pipes/topics-filter-pipe.pipe';
import { SimulatorCasosFluxogramaCopiarEtapaModalComponent } from './pages/simulador/simulator-casos-fluxograma-copiar-etapa-modal/simulator-casos-fluxograma-copiar-etapa-modal.component';
import { UserProductsComponent } from './dialogs/user-products/user-products.component';
import { MatStepperModule } from "@angular/material/stepper";
import { JourneyStepsComponent } from './pages/journey/journey-steps/journey-steps.component';
import { JourneyStepSearchComponent } from './dialogs/journey-step-search/journey-step-search.component';
import { QuizQuestionsComponent } from './pages/questions/quiz-questions/quiz-questions.component';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { UsersDetailsComponent } from './pages/users/users-details/users-details.component';
import { UsersCancelPlanComponent } from './pages/users/users-cancel-plan/users-cancel-plan.component';
import { UsersFormComponent } from './pages/users/users-form/users-form.component';
import { UsersAddPlanComponent } from './pages/users/users-add-plan/users-add-plan.component';
import { UsersAddOldPlanComponent } from './pages/users/users-add-old-plan/users-add-old-plan.component';
import { UsersAddManualPaymentComponent } from './pages/users/users-add-manual-payment/users-add-manual-payment.component';
import { UsersAddManualPaymentBoletoComponent } from './pages/users/users-add-manual-payment-boleto/users-add-manual-payment-boleto.component';
import { ListarCursosComponent } from './pages/cursos/listar-cursos/listar-cursos.component';
import { CadastrarCursosComponent } from './pages/cursos/cadastrar-cursos/cadastrar-cursos.component';
import { ConfirmDeleteComponent } from './dialogs/confirm-delete/confirm-delete.component';
import { TurmasListComponent } from './pages/turmas/turmas-list/turmas-list.component';
import { TurmasFormComponent } from './pages/turmas/turmas-form/turmas-form.component';
import { NotasListComponent } from './pages/notas/notas-list/notas-list.component';
import { NotasEditComponent } from './pages/notas/notas-edit/notas-edit.component';
import { EditDateComponent } from './dialogs/edit-date/edit-date.component';
import { EditJourneyStepDateComponent } from './dialogs/edit-journey-step-date/edit-journey-step-date.component';
import { QuizFormComponent } from './pages/quizzes/form/quiz-form.component';
import { QuestionFormDialogComponent } from './dialogs/question-form/question-form-dialog.component';
import { QuizListComponent } from './pages/quizzes/list/quiz-list.component';
import { PreviewContentComponent } from './dialogs/preview-content/preview-content.component';
import { ReportUsersComponent } from './pages/reports/report-users/report-users.component';
import { QuillModule } from 'ngx-quill';


registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    MomentJsPipe,
    HomeComponent,
    EstruturaComponent,
    LoginComponent,
    FormatPhonePipe,
    StripTagsPipe,
    ListarComponent,
    FormComponent,
    CheckImageUrlPipe,
    FormFieldsComponent,
    MinhasAulasComponent,
    MinhasAulasFormComponent,
    MinhasAulasAgendamentoComponent,
    MinhasAulasAddVideoComponent,
    MinhasAulasVideoComponent,
    MinhasAulasVideoAddRevisaoComponent,
    Sec2MinSecPipe,
    MinhasAulasAddArquivoComponent,
    CalendarioComponent,
    CalendarioExibirComponent,
    UsuariosComponent,
    UsuariosPermissoesComponent,
    UsuariosInstrutoresComponent,
    UsuariosFormComponent,
    StatusAulaComponent,
    FaqComponent,
    MinhasAulasTopicosComponent,
    MinhasAulasPlaylistsComponent,
    MinhasAulasVideoAddSumarioComponent,
    UsuariosTrocarSenhaComponent,
    KeepHtmlPipe,
    DialogPadraoComponent,
    SimulatorCasosListarComponent,
    SimulatorCasosFormComponent,
    CleanTextForURLPipe,
    SimulatorCasosFluxogramaComponent,
    SimulatorCasosFluxogramaEtapaModalComponent,
    SimulatorCasosFluxogramaProtocoloModalComponent,
    MinhasAulasAtividadeComponent,
    SimulatorAnamnesisListarComponent,
    SimulatorAnamnesisFormComponent,
    SimulatorFeedbacksListarComponent,
    SimulatorFeedbacksFormComponent,
    SimulatorFeedbacksAddVideoComponent,
    QuestionListComponent,
    QuestionFormComponent,
    NotificationsComponent,
    RegistrationCodeComponent,
    StudioBlockFormComponent,
    StudioBlockInfoModalComponent,
    TopicsListComponent,
    TopicThumbComponent,
    InstructorsComponent,
    InstructorsFormComponent,
    ReportViewComponent,
    ReportVoucherComponent,
    ReportUsersComponent,
    ReportVoucherDetailsComponent,
    UserSearchComponent,
    CheckUserInfoComponent,
    SearchStudioBlockComponent,
    InstructorsFormPageComponent,
    InstructorsFormDialogComponent,
    TopicsFilterPipePipe,
    SimulatorCasosFluxogramaCopiarEtapaModalComponent,
    UserProductsComponent,
    JourneyStepsComponent,
    JourneyStepSearchComponent,
    QuizQuestionsComponent,
    UsersListComponent,
    UsersDetailsComponent,
    UsersCancelPlanComponent,
    UsersFormComponent,
    UsersAddPlanComponent,
    UsersAddOldPlanComponent,
    UsersAddManualPaymentComponent,
    UsersAddManualPaymentBoletoComponent,
    ListarCursosComponent,
    CadastrarCursosComponent,
    ConfirmDeleteComponent,
    TurmasListComponent,
    TurmasFormComponent,
    NotasListComponent,
    NotasEditComponent,
    EditDateComponent,
    EditJourneyStepDateComponent,
    PreviewContentComponent,
    QuizFormComponent,
    QuestionFormDialogComponent,
    QuizListComponent
  ],
  entryComponents: [
    MinhasAulasAddVideoComponent,
    MinhasAulasVideoAddRevisaoComponent,
    MinhasAulasAddArquivoComponent,
    MinhasAulasTopicosComponent,
    MinhasAulasPlaylistsComponent,
    MinhasAulasVideoAddSumarioComponent,
    SimulatorCasosFluxogramaEtapaModalComponent,
    SimulatorCasosFluxogramaProtocoloModalComponent,
    SimulatorCasosFluxogramaCopiarEtapaModalComponent,
    SimulatorFeedbacksAddVideoComponent,
    DialogPadraoComponent,
    StudioBlockFormComponent,
    StudioBlockInfoModalComponent,
    UserProductsComponent,
    UserSearchComponent,
    SearchStudioBlockComponent,
    JourneyStepSearchComponent,
    InstructorsFormDialogComponent,
    UsersCancelPlanComponent,
    UsersAddPlanComponent,
    UsersAddOldPlanComponent,
    UsersAddManualPaymentComponent,
    UsersAddManualPaymentBoletoComponent,
    ListarCursosComponent,
    CadastrarCursosComponent,
    ConfirmDeleteComponent,
    NotasEditComponent,
    EditDateComponent,
    EditJourneyStepDateComponent,
    PreviewContentComponent,
    QuestionFormDialogComponent,
    QuizListComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    EditorModule,
    MatBadgeModule,
    MatTabsModule,
    MatMenuModule,
    MatCardModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatListModule,
    MatDividerModule,
    MatSnackBarModule,
    MatRippleModule,
    MatCheckboxModule,
    DragDropModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    FormsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSliderModule,
    SimpleNotificationsModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSortModule,
    MatSidenavModule,
    MatProgressBarModule,
    FullCalendarModule,
    MatTreeModule,
    MatIconModule,
    Ng2PanZoomModule,
    MatStepperModule,
    CommonModule,
    QuillModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    {provide: LOCALE_ID, useValue: 'pt'},
    {provide: RouteReuseStrategy, useClass: CustomRouteReuseStategy },
    HttpClientModule,
    CleanTextForURLPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
