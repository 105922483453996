import { Component, OnInit } from '@angular/core';
import { HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { User } from '../../utils/User';
import { environment } from '../../../environments/environment';
import { ApiRequestService } from "../../services/api-request.service";
import { EventEmitterService } from '../../services/event-emitter.service';
import { SaveScroll } from '../../utils/CustomRouteReuseStategySaveScroll';
import { ProdutoSelecionado } from '../../utils/ProdutoSelecionado';

@Component({
	selector: 'app-estrutura',
	templateUrl: './estrutura.component.html',
	styleUrls: ['./estrutura.component.scss']
})
export class EstruturaComponent extends SaveScroll implements OnInit {

	menuCondensado:boolean = false;
	menuMobileAberto:boolean = false;

	_ProdutoSelecionado:any;
	produtos:any = [];

	usuario:User = new User();

	mostrarNotificacao:boolean = false;
	mostrarMensagem:boolean = false;

	notifications:any = {};

	@ViewChild('main') mainEl:ElementRef;

	menu:any[] = [
		{
			nome: "Administração",
			icone: "user-cog",
			submenu: [
				{
					nome: "Usuários",
					url: "/users",
					permissoes: ["master", "is"]
				},
				{
					nome: "Vouchers",
					url: "/registration-code",
					permissoes: ["master", "is"]
				}
			]
		},
		{
			nome: "Conteúdos",
			icone: "book",
			submenu: [
				{
					nome: "Calendário do Estúdio",
					url: "/calendario"
				},
				{
					nome: "Aulas",
					url: "/minhas_aulas",
					permissoes: ["master", "instructor", "coordenator", "videomaker"]
				},
				{
					nome: "Professores",
					url: "/instructors",
					permissoes: ["coordenator"]
				}
			]
		},
		/*{
			nome: "Mídia",
			icone: "share-alt",
			submenu: [
				{
					nome: "Códigos de Cadastro",
					url: "/registration-code",
					permissoes: ["media"]
				},
				{
					nome: "Link do formulário de Feedback",
					url: "/config_configs/edit/1",
					permissoes: ["media"]
				}
			]
		},*/
		{
			nome: "Simulador",
			icone: "procedures",
			submenu: [
				{
					nome: "Tutorial",
					url: "/simulador_tutorial/list",
					permissoes: ["simulator-master"]
				},{
					nome: "Parâmetros",
					url: "/simulador_parametros/list",
					permissoes: ["simulator-master"]
				},
				{
					nome: "Ações",
					url: "/simulador_acoes/list",
					permissoes: ["simulator-master"]
				},
				{
					nome: "Anamneses",
					url: "/simulador/anamnesis",
					permissoes: ["simulator-master"]
				},
				{
					nome: "Feedbacks",
					url: "/simulador/feedbacks",
					permissoes: ["simulator-master"]
				},
				{
					nome: "Protocolos",
					url: "/simulador/protocol",
					permissoes: ["simulator-master"]
				},
				{
					nome: "Casos Clínicos",
					url: "/simulador/case",
					permissoes: ["simulator-master"]
				},
				{
					nome: "Missões",
					url: "/simulador_missoes/list",
					permissoes: ["simulator-master"]
				}
			]
		},
		{
			nome: "Turmas",
			icone: "box",
			submenu: [
		        {
		          nome: "Áreas",
		          url: "/config_areas/list",
		          permissoes: ["master"]
		        },
				{
					nome: "Turmas",
					url: "/turmas",
					permissoes: ["master"]
				},
		        {
		          nome: "Promoções",
		          url: "/config_productOffers/list",
		          permissoes: ["master"]
		        },
				{
					nome: "Categorias",
					url: "/config_categories/list",
					permissoes: ["master"]
				},
				{
					nome: "Disciplinas",
					url: "/config_disciplinas/list",
					permissoes: ["master"]
				},
				{
					nome: "Tópicos",
					url: "/topics",
					permissoes: ["master"]
				},
				{
					nome: "Questões",
					url: "/question",
					permissoes: ["master"]
				},
				{
					nome: "Provas",
					url: "/prova",
					permissoes: ["master"]
				},
				{
					nome: "Notas",
					url: "/notas-list",
					permissoes: ["master"]
				},
		    ]
		},
		{
			nome: "Configurações",
			icone: "cog",
			submenu: [

				{
					nome: "Variaveis do Sistema",
					url: "/config_configs/list",
					permissoes: ["master"]
				},
				/*{
					nome: "Vouchers",
					url: "/config_vouchers/list",
					permissoes: ["master"]
				},*/
				{
					nome: "Planos e Preços",
					url: "/config_planos/list",
					permissoes: ["master"]
				},
				{
					nome: "Modelos de Contrato",
					url: "/config_contractModels/list",
					permissoes: ["master"]
				},
				{
					nome: "Termos",
					url: "/config_terms/list",
					permissoes: ["master"]
				},
				/*{
					nome: "Bloqueio do Estúdio",
					url: "/config_studioBlock/list",
					permissoes: ["master", "videomaker", "secretary"]
				},*/
				{
					nome: "Cursos",
					url: "/cursos",
					permissoes: ["master"]
				},
				{
					nome: "Usuários",
					url: "/usuarios",
					permissoes: ["master", "secretary"]
				},
				{
					nome: "Review do Vídeo",
					url: "/config_videoBadges/list",
					permissoes: ["master"]
				},
				{
					nome: "Publicos Alvo",
					url: "/config_targetAudience/list",
					permissoes: ["master"]
				},

				{
					nome: "FAQ",
					url: "/config_faq/list",
					permissoes: ["master"]
				},
				/*{
					nome: "Minhas Playlists",
					url: "/config_playlists/list",
					permissoes: ["instructor", "coordenator"]
				},
				{
					nome: "Playlists Oficiais",
					url: "/config_playlistsMaster/list",
					permissoes: ["master"]
				}*/
			]
		}

	];

	constructor(
		public apiRequest: ApiRequestService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {
		super();

		this.apiRequest.Get("products").subscribe((ret:any)=>{
	        this.produtos = ret;
	    });

		EventEmitterService.get("setScrollPosition").subscribe((res:any)=>{
			this.setScroll(res);
		})

		if(this.usuario.CheckLogin() && this.usuario.CheckPermission("master")){
			this.LoadReports();
		}
	}

	selecionarProduto(produto) {
		if(produto == 0) {
			ProdutoSelecionado.id = 0;
			ProdutoSelecionado.name = 'Todos';
		} else {
			ProdutoSelecionado.id = produto.id;
			ProdutoSelecionado.name = produto.name;
		}
		localStorage.setItem("produtoSelecionado",JSON.stringify(ProdutoSelecionado));
		EventEmitterService.get("refreshProduct").emit();
		//console.log(this.router);
		this.router.navigate([this.router.url.split('?')[0]]);
	}

	onScroll($event){
		EventEmitterService.get("saveScrollPosition").emit({
			router: this.router,
			scrollTop: $event.srcElement.scrollTop
		});
	}

	setScroll(position:number){
		setTimeout(()=>{
			this.mainEl.nativeElement.scrollTop = position;
		}, 10);
	}

	ngOnInit() {

		this._ProdutoSelecionado = ProdutoSelecionado;

		this.menuCondensado = localStorage.getItem("menuCondensado")=="s";

		if(!this.usuario.CheckLogin()){
			this.router.navigate(["login"]);
		} else {
			this.GetUserData();
			this.Notificacoes();

			if(this.usuario.CheckPermission("coordenator")){
				let i = this.menu.findIndex(v=>v.nome=="Conteúdos");
				this.menu[i].nome = "Área do Coordenador";
				this.menu[i].icone = "star";
			}
		}
	}

	Notificacoes(){
		this.apiRequest.Get("notifications").subscribe(
			(res:any) => {
				this.notifications = res;
				setTimeout(res=>{
					this.Notificacoes();
				}, 5*60*1000);
			},
			error => {
			}

		)
	}

	NotificacoesLerTodas(){
		this.apiRequest.Post("notifications/readAll", {}).subscribe(
			(res:any) => {
				this.notifications.unread = 0;
			},
			error => {
			}

		)
	}

	ToggleNotificacoes(){
		this.mostrarNotificacao = !this.mostrarNotificacao;

		if(this.mostrarNotificacao) this.NotificacoesLerTodas();
	}

	GetUserData(){

		this.apiRequest.Get("../user/me").subscribe(

			(res:any) => {
				this.usuario.data.user = res;
				this.usuario.Save();
			},
			error => {
				if(error.status==401){
					this.router.navigate(["login"]);
				} else {
					console.log(error);
					alert("Erro ao retornar dados do usuário");
				}
			}

		)
	}

	ChecarUsuario(){

		if(!this.usuario.CheckLogin()){
			this.router.navigate(["login"]);

		} else if(!this.usuario.CheckPermissions()){
			alert("Você não tem permissão para acessar essa área");
			this.router.navigate(["login"]);
		}

	}

	Logout(){
		this.usuario.Logout();
		this.router.navigate(["./login"]);
	}

	CondensarMenu(){
		this.menuCondensado = !this.menuCondensado;

		localStorage.setItem("menuCondensado", this.menuCondensado ? "s" : "n");
	}

	AbrirMenu(m:any) {

		let prox:boolean = !m.aberto;

		for(let i:number = 0; i<this.menu.length; i++){
			this.menu[i].aberto = false;
		}

		m.aberto = prox;
	}

	AbrirMenuMobile() {
		this.menuCondensado = false;
		this.menuMobileAberto = !this.menuMobileAberto;
	}

	ChecarMenuUsuario(menu:any) {
		let requisitos:string[] = [];
		for(let i:number=0; i<menu.submenu.length; i++){
			if(menu.submenu[i].permissoes){
				requisitos = requisitos.concat(menu.submenu[i].permissoes);
			}
		}

		return requisitos.length==0 || this.usuario.CheckPermissionArray(requisitos);
	}

	MenuNavigate(link:string){

		EventEmitterService.get("deleteAllRouteReuse").emit();
		this.router.navigate([link]);

	}

	LoadReports(){

		this.apiRequest.Get("reports").subscribe((res:any)=>{

			let newMenu = {
				nome: "Relatórios",
				icone: "file-alt",
				submenu: [
					{
						nome: 'Alunos',
						url: '/report/users',
						permissoes: ['master']
					}
				]
      };

      newMenu.submenu.push({
					nome: 'Voucher',
					url: "/report/voucher",
					permissoes: ["master"]
				});

			// for(let i:number=0; i<res.data.length; i++){

			// 	newMenu.submenu.push({
			// 		nome: res.data[i][res.key],
			// 		url: "/report/"+res.data[i][res.key],
			// 		permissoes: ["master"]
			// 	});

			// }

			this.menu.push(newMenu);

		});

	}

}
