import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from 'src/app/services/api-request.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationType, NotificationsService } from 'angular2-notifications';

export interface Curso {
  id: number;
  name: string;
  slug: string;
  image: string;
  code: string;
  sigla: string;
  created_at: string;
  updated_at: string;
  forma_pagamento: string;
  status: string;
  disponivel_para_venda: string;
}

export interface PaymentPlan {
  tipo: string;
  parcelas: string;
  valor_parcelas: string;
  total: string;
}

export interface HttpPostResponse {
  data: Curso;
  message: string;
}

@Component({
  selector: 'app-cadastrar-cursos',
  templateUrl: './cadastrar-cursos.component.html',
  styleUrls: ['./cadastrar-cursos.component.scss']
})
export class CadastrarCursosComponent implements OnInit {
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    slug: ['', Validators.required],
    image: ['', Validators.required],
    sigla: ['', Validators.required],
    code: ['', Validators.required],
    status: ['', Validators.required],
    disponivel_para_venda: ['', Validators.required],
    id: [null],
  });

  formPayment: FormGroup = this.formBuilder.group({
    tipo: ['', Validators.required],
    parcelas: ['', Validators.required],
    valor_parcelas: ['', Validators.required],
    valor_antecipado_parcelas: ['', Validators.required],
    total: ['', Validators.required],
  });
  
  curso: Curso;
  columnsToDisplay = ["name", "acoes"];
  id: number;
  isLoading = false;
  isSaveLoading = false;
  imagePreview: string;
  addPaymentPlan = false;
  paymentPlans: PaymentPlan[] = [];
  isAvailable = false;
  isActive = false;

	constructor(
		private router: Router,
    private api: ApiRequestService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private notifications: NotificationsService,
	) { }

	ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.id = params['id'];
        this.getCursoById();
      }
    });

	}

  getCursoById() {
    const url = `cursos/${this.id}`;
    this.isLoading = true;

    this.api.Get(url).subscribe(({
      next: (res: any) => {
        this.curso = res.data;
        this.preencheDadosFormulario(res.data);
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      }
    }));
  }

  onFileChanged(event) {
		let f:File = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imagePreview = e.target.result;
    };

    reader.readAsDataURL(f);

		this.api.UploadPosS3(f).subscribe((res:any)=>{
      this.form.get('image').setValue(res.file);
      console.log(this.form.get('image').value);
		});
	}

	//UPLOAD DE ARQUIVOS
	onFileChangedS3(event,) {
		let f:File = event.target.files[0];

		this.api.UploadS3(f).then((ret:any)=>{
			console.log(ret);
		});
	}

  showAddPaymentSection() {
    this.addPaymentPlan = true;
  }

  onSubmit() {
    if (this.curso) {
      this.update();
    } else {
      this.save();
    }
  }

  save() {
    const url = `cursos/create`;
    this.isSaveLoading = true;
    const data = {
      ...this.form.value,
      forma_pagamento: JSON.stringify(this.paymentPlans)
    }

    console.log(data);

    this.api.Post(url, data).subscribe(({
      next: (res: HttpPostResponse) => {
        this.isSaveLoading = false;
        this.notifications.create("Sucesso!", res.message, NotificationType.Success, { timeOut: 2500 } );
        
        setTimeout(() => {
          this.router.navigate(['/cursos']);
        }, 1000);
      },
      error: (err) => {
        console.log(err);
      }
    }));
  }

  update() {
    const url = `cursos/update`;
    this.isSaveLoading = true;
    this.form.get('id').setValue(this.id);

    const data = {
      ...this.form.value,
      forma_pagamento: JSON.stringify(this.paymentPlans)
    };

    this.api.Post(url, data).subscribe(({
      next: (res: HttpPostResponse) => {
        console.log(res);
        this.isSaveLoading = false;
        this.notifications.create("Sucesso!", res.message, NotificationType.Success, { timeOut: 2500 } );

        setTimeout(() => {
          this.router.navigate(['/cursos']);
        }, 1000);
      },
      error: (err) => {
        console.log(err);
      }
    }));
  }

  preencheDadosFormulario(data: Curso) {
    const {
      name,
      slug,
      image,
      sigla,
      code,
      id,
      forma_pagamento,
      status,
      disponivel_para_venda
    } = data;

    this.paymentPlans = JSON.parse(forma_pagamento);
    status == 'ativo' ? this.isActive = true : this.isActive = false;
    disponivel_para_venda == 'sim' ? this.isAvailable = true : this.isAvailable = false;

    this.form.patchValue({
      name,
      slug,
      sigla,
      code,
      image,
      id,
      status,
      disponivel_para_venda
    });
  }

  GetField(field:string){
		return this.form.get(field);
	}

  GetError(field:string){
		if(this.form.get(field).hasError('required')){
			return "Este campo é obrigatório";
		}
  }

  savePaymentPlan() {
    this.paymentPlans = [...this.paymentPlans, this.formPayment.value];
    this.notifications.create("Sucesso!", 'Forma de pagamento adicionado com sucesso!', NotificationType.Success, { timeOut: 1500 } );
    this.formPayment.reset();
  }

  changeStatus(event: any) {
    if (event.checked) {
      this.form.get('status').setValue('ativo');
    } else {
      this.form.get('status').setValue('inativo');
    }
  }

  changeDisponivelParaVenda(event: any) {
    if (event.checked) {
      this.form.get('disponivel_para_venda').setValue('sim');
    } else {
      this.form.get('disponivel_para_venda').setValue('nao');
    }
  }

  checkTypePayment(event: any) {
    if (event.value == 'cartao a vista' || event.value == 'pix' || event.value == 'boleto a vista') {
      this.formPayment.get('parcelas').setValue('1');
    } else {
      this.formPayment.get('parcelas').setValue('');
    }
  }

  calcTotal() {
    const parcelas = +this.formPayment.get('parcelas').value;
    const valorParcelas = +this.formPayment.get('valor_parcelas').value;
    const total = parcelas * valorParcelas;

    this.formPayment.get('total').setValue(`${total}`);
  }

  paymentType(tipo: string){
    if (tipo=="invoice"){
      return "Boleto";
    }
    if (tipo=="once_credit_card"){
      return "Cartão 1x";
    }
    if (tipo=="installments_credit_card"){
      return "Cartão parcelado";
    }
    if (tipo=="recurrence"){
      return "Recorrência";
    }
    if (tipo=="pix"){
      return "Pix";
    }
  }
}