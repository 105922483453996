import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ApiRequestService } from '../../services/api-request.service';

@Component({
  selector: 'edit-date',
  templateUrl: './edit-date.component.html',
  styleUrls: ['./edit-date.component.scss']
})
export class EditDateComponent {
	data:string = "";

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<EditDateComponent>,
	) {}

	close(param: string) {
		if (param === 'Salvar') {
			this.dialogRef.close(this.data);
		} else {
			this.dialogRef.close();
		}
	}
}