import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Config } from "../../utils/Config";
import { ApiRequestService } from '../../services/api-request.service';

import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JourneyStepSearchComponent } from "../journey-step-search/journey-step-search.component";
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { quillConfig } from 'src/app/utils/QuillConfig';


export interface Topic {
	id: number;
	name: string;
}


@Component({
  selector: 'app-question-form-dialog',
  templateUrl: './question-form-dialog.component.html',
  styleUrls: ['./question-form-dialog.component.scss']
})
export class QuestionFormDialogComponent implements OnInit {

	formCadastro:FormGroup= new FormGroup({
		question: new FormControl('', Validators.required),
		comment: new FormControl('', Validators.required),
		keywords: new FormControl('', Validators.required),
	});


	question_alternatives:any[] = [];
	contents:any[] = [];
	config:any = Config;
	quilConfig = quillConfig;

	constructor(
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<QuestionFormDialogComponent>,
	) {}

	ngOnInit() {

		this.route.params.subscribe(ret => {
			if(ret.id!=undefined){
				this.CarregarDados(ret.id);
			}
		});
	}

	dados;
	CarregarDados(id:number){

		this.api.Get("question/"+id).subscribe((res:any)=>{
			this.dados = res;

			this.formCadastro.setValue({
				question: res.question,
				keywords: res.keywords,
				comment: res.comment
			});

			this.question_alternatives = res.question_alternatives;
			res.contents.forEach((v:any)=>{
				this.contents.push({
					id: v.id,
					title: v.title,
					type: v.pivot.type
				});
			});

		}, error=> {
			alert("Erro ao carregar dados. Contate o administrador");
			console.log(error.error);
		})
	}


	GetField(field:string, form:FormGroup = this.formCadastro){
		return form.get(field);
	}

	GetError(field:string, form:FormGroup = this.formCadastro){
		if(form.get(field).hasError('required')){
			return "Este campo é obrigatório";
		} else if(form.get(field).hasError('email')){
			return "E-mail inválido";
		} else if(form.get(field).hasError('minlength')){
			return "A senha precisa ter no mínimo 6 caracteres";
		} else if(form.get(field).hasError('pwdMatch')){
			return "As senhas não coincidem";
		} else if(form.get(field).hasError('emailTaken')){
			return "Este e-mail já está cadastrado no SimSave. ";
		} else {
			return "Preencha este campo corretamente";
		}
	}

	dropItem(event: CdkDragDrop<any[]>) {
		moveItemInArray(this.contents, event.previousIndex, event.currentIndex);
	}

	AddContent(){

		const dialogRef = this.dialog.open(JourneyStepSearchComponent, {
			width: '600px',
			data: {
				types: 'contents'
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result) {
				if(result.type=="content"){
					this.contents.push({
						id: result.content_id,
						title: result.title,
						type: "none",
					});
				} else {
					alert("Esta área aceita apenas conteúdos de vídeo");
				}
			}
		});

	}

	RemoveContent(i:number, content:any){
		this.contents.splice(i, 1);
	}

	AddAnswer(){
		this.question_alternatives.push({});
	}

	RemoveAnswer(i:number){
		this.question_alternatives.splice(i, 1);
	}

	MarkAnswerCorrect(index){
		for(let i:number=0; i<this.question_alternatives.length; i++){
			this.question_alternatives[i].correct = i==index;
		}
	}

	enviandoForm:boolean = false;
	
	Salvar(){

		this.formCadastro.get('question').markAsTouched();
		this.formCadastro.get('comment').markAsTouched();

		if(this.formCadastro.valid){

			if(this.question_alternatives.length<2){
				alert("A questão precisa ter pelo menos 2 respostas, sendo uma delas correta.");
				return;
			}

			let correta = -1;
			for(let i:number=0; i<this.question_alternatives.length; i++){
				if(this.question_alternatives[i].correct) correta = i;
			}
			if(correta<0){
				alert("Assinale a resposta correta clicando no ícone do lado esquerdo do texto da resposta.");
				return;
			}


			let data = this.formCadastro.value;
			data.question_alternatives = this.question_alternatives;
			data.contents = {};

			this.contents.forEach((v:any)=>{
				data.contents[v.id] = {type: v.type};
			});

			let url = this.dados==undefined ? "question" : "question/"+this.dados.id;

			this.enviandoForm = true;
			this.api.Post(url, data).subscribe(res=>{

				this.notifications.create("Sucesso!", "Registro salvo com sucesso", NotificationType.Success, { timeOut: 5000 } );

				this.dialogRef.close(res);
			}, error=> {
				alert("Erro ao salvar questão. Contate o administrador");
				this.enviandoForm = false;
			})
		}
	}

	Voltar(): void {
		this.dialogRef.close();
	}

}
