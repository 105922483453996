import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiRequestService } from "../../../services/api-request.service";
import { Config } from "../../../utils/Config";
import { User } from "../../../utils/User";
import { MatDialog } from "@angular/material/dialog";
import { UsersCancelPlanComponent } from "../users-cancel-plan/users-cancel-plan.component";
import { UsersAddPlanComponent } from "../users-add-plan/users-add-plan.component";
import { UsersAddOldPlanComponent } from "../users-add-old-plan/users-add-old-plan.component";
import { UsersAddManualPaymentComponent } from "../users-add-manual-payment/users-add-manual-payment.component";
import { UsersAddManualPaymentBoletoComponent } from "../users-add-manual-payment-boleto/users-add-manual-payment-boleto.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-users-details",
  templateUrl: "./users-details.component.html",
  styleUrls: ["./users-details.component.scss"],
})
export class UsersDetailsComponent implements OnInit {
  userData: any;
  config = Config;
  currentUser: User = new User();

  simulationFinalization = {
    none: "Não finalizado",
    success: "Sucesso",
    fail: "Falha",
  };

  constructor(
    public api: ApiRequestService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.params.subscribe((p) => {
      this.GetData(p.id);
    });
  }

  GetData(id: number) {
    this.userData = undefined;

    this.api.Get("user/" + id + "/details").subscribe((res) => {
      this.userData = res;
      console.log(this.currentUser.data.user.id);

      this.userData.content_views.forEach(item => {
        const updatedAtDate = new Date(item.updated_at);
        updatedAtDate.setHours(updatedAtDate.getHours() - 6);
        item.updated_at = updatedAtDate.toISOString().slice(0, 19).replace('T', ' ');
      });
    });
  }

  CanRefund(payment: any) {
    var days = 7;
    let date: Date = new Date();
    let res: number = date.setTime(date.getTime() - days * 24 * 60 * 60 * 1000);
    let pay_date = new Date(payment.created_at);

    return res < pay_date.getTime() && payment.status == "CAPTURED";
  }

  Refund(payment: any) {
    if (
      confirm(
        "Tem certeza que deseja estornar este pagamento? Ao estornar o pagamento, essa assinatura será cancelada."
      )
    ) {
      this.api.Post("payment/" + payment.id + "/void", {}).subscribe((res) => {
        this.GetData(this.route.snapshot.params.id);
        alert("Pagamento estornado com sucesso!");
      });
    }
  }

  TurnOffAutoRenovation(plan: any) {
    this.api
      .Post("../user/plan/" + plan.id, { auto_renovation: false })
      .subscribe((res: any) => {
        plan.auto_renovation = res.auto_renovation;
      });
  }

  CancelPlan(plan): void {
    const dialogRef = this.dialog.open(UsersCancelPlanComponent, {
      width: "500px",
      data: { plan: plan },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.GetData(this.userData.id);
    });
  }

  MakeSale(plan): void {
    if (confirm("Confirmar venda?")) {
      this.api
        .Put(`user/${this.currentUser.data.user.id}/insideSale`, {
          id: plan.id,
          product_id: plan.product.id,
        })
        .subscribe(
          (res: any) => {
            this.route.params.subscribe((p) => {
              this.GetData(p.id);
            });
          },
          (error: any) => {
            console.log(error);
          }
        );
    }
  }

  RemoveSale(plan): void {
    if (confirm("Confirmar remoção?")) {
      console.log(this.currentUser, plan);
      this.api
        .Delete(
          `user/${this.currentUser.data.user.id}/insideSale?id=${plan.id}&product_id=${plan.product.id}`
        )
        .subscribe(
          (res: any) => {
            this.route.params.subscribe((p) => {
              this.GetData(p.id);
            });
          },
          (error: any) => {
            console.log(error);
          }
        );
    }
  }

  // openAddPlanDialog() {
  //   const dialogRef = this.dialog.open(UsersAddPlanComponent, {
  //     width: "600px",
  //     data: { user: this.userData }
  //   });

  //   dialogRef.afterClosed().subscribe((res: any) => {
  //     this.GetData(res.user_id);
  //   })
  // }

  openOldPlanDialog() {
    const dialogRef = this.dialog.open(UsersAddOldPlanComponent, {
      width: "600px",
      data: { user: this.userData }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if(res){
        this.GetData(res.user_id);
      }
    })
  }

  openManualPaymentDialog() {
    const dialogRef = this.dialog.open(UsersAddManualPaymentComponent, {
      width: "600px",
      data: { user: this.userData }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      this.GetData(res.user_id);
    })
  }

  openManualPaymentDialogBoleto() {
    const dialogRef = this.dialog.open(UsersAddManualPaymentBoletoComponent, {
      width: "600px",
      data: { user: this.userData }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      this.GetData(res.user_id);
    })
  }

  gerarNotas(plan) {
    console.log("user", this.userData.id);
    console.log("plan", plan);
    
    this.api.Post(`user/${ this.userData.id}/gerar-notas/${plan.product.id}`, {
    }).subscribe(res => {
      alert("Notas atualizadas com sucesso!");
    })


	}

  impersonateUser(email) {

		var a: HTMLAnchorElement = document.createElement('a');
		a.href = environment.eadUrl+encodeURIComponent(email);
		a.target = "_blank";
		document.body.appendChild(a);
		a.click();
	}
}
