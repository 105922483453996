import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiRequestService } from '../../services/api-request.service';
import { DatePipe } from '@angular/common';
import * as Player from "@vimeo/player/dist/player.js";

@Component({
  selector: 'preview-content',
  templateUrl: './preview-content.component.html',
  styleUrls: ['./preview-content.component.scss'],
  providers: [DatePipe]
})
export class PreviewContentComponent {
	modal_info:any = undefined;
	video:any = undefined;
	player:any = undefined;

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<PreviewContentComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private datePipe: DatePipe

	) {
		this.modal_info = dialogData;
		this.video = dialogData.video;
		if(this.video){
			let idVideo = this.video.uri.replace("/videos/", "");

			try {
				window.setTimeout(()=>{
					this.player = new Player('videoPlayer', {
						//url: "https://vimeo.com/"+idVideo,
						id: idVideo,
						responsive: true
					});

					this.player.on('error', (error) => {
						console.log('Erro ao carregar o player:', error);
						alert('O vídeo não pode ser carregado. Por favor, tente novamente mais tarde.');
					});

				}, 1000);
			} catch (error) {
				console.log("Erro", error);
				
			}
		}

	}

	editContent() {

		var a: HTMLAnchorElement = document.createElement('a');
		a.href = "/minhas_aulas/edit/"+this.modal_info.content_id;
		a.target = "_blank";
		document.body.appendChild(a);
		a.click();

	}

	close(param: string) {

		this.dialogRef.close();

	}
}