import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ApiRequestService } from '../../services/api-request.service';
import { InstructorsFormDialogComponent } from '../../pages/instructors/instructors-form-dialog/instructors-form-dialog.component';

export interface Data {
	text: string
}

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent {
	text:string = "";

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<ConfirmDeleteComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: Data,
		public dialog: MatDialog
	) {
		this.text = this.dialogData.text;
	}

	close(param: string) {
		if (param === 'Cancelar') {
			this.dialogRef.close('Cancelar');
		} else {
			this.dialogRef.close('Excluir');
		}
	}
}