import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ApiRequestService } from '../../../services/api-request.service';
import { Config } from '../../../utils/Config';
import * as moment from 'moment';

@Component({
  selector: 'app-users-cancel-plan',
  templateUrl: './users-cancel-plan.component.html',
  styleUrls: ['./users-cancel-plan.component.scss']
})
export class UsersCancelPlanComponent implements OnInit {

	config = Config;
	monthsCount = 0;
	monthsToRefund = 0;
	product:any;

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<UsersCancelPlanComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData:any,
		public dialog: MatDialog
	) { }

	ngOnInit() {
		console.log(this.dialogData);
		let end = moment(this.dialogData.plan.created_at);
		let now = moment();
		let duration = moment.duration(now.diff(end));

		this.monthsCount = Math.round(duration.asMonths());
		this.monthsToRefund = this.monthsCount;

		this.LoadProduct();
	}

	LoadProduct(){
		this.api.Get("../product/"+this.dialogData.plan.product.slug).subscribe(res=>this.product=res);
	}

	CalcRefund() {
		if(!this.product) return 0;
		return this.dialogData.plan.total_value - this.monthsToRefund*this.product.monthly_value;
	}

	CalcValidUntil(){
		moment.locale("pt-br");
		let start = moment(this.dialogData.plan.created_at);
		return start.add(this.monthsToRefund, 'M');
	}

	sending:boolean = false;
	Refund(){
		this.sending = true;
		this.api.Delete("../user/cancelPlan/"+this.dialogData.plan.id+"?refund="+this.CalcRefund()+"&valid_until="+this.CalcValidUntil().toISOString()).subscribe(res=>{
			alert("Plano cancelado com sucesso!");
			this.dialogRef.close(true);
		}, error=>{
			this.sending = false;
			alert("Erro ao cancelar plano. Confira o valor e tente novamente.");
		});
	}

	Close(){
		this.dialogRef.close();
	}

}
