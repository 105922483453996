import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { EstruturaComponent } from './pages/estrutura/estrutura.component';

import { LoginComponent } from './pages/login/login.component';

import { FaqComponent } from './pages/faq/faq.component';

import { ListarComponent } from './pages/crud/listar/listar.component';
import { FormComponent } from './pages/crud/form/form.component';
import { MinhasAulasComponent } from './pages/minhas-aulas/minhas-aulas.component';
import { MinhasAulasFormComponent } from './pages/minhas-aulas/minhas-aulas-form/minhas-aulas-form.component';
import { MinhasAulasAgendamentoComponent } from './pages/minhas-aulas/minhas-aulas-agendamento/minhas-aulas-agendamento.component';
import { MinhasAulasVideoComponent } from './pages/minhas-aulas/minhas-aulas-video/minhas-aulas-video.component';
import { MinhasAulasAtividadeComponent } from './pages/minhas-aulas/minhas-aulas-atividade/minhas-aulas-atividade.component';

import { CalendarioExibirComponent } from './pages/calendario-exibir/calendario-exibir.component';

import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { UsuariosPermissoesComponent } from './pages/usuarios/usuarios-permissoes/usuarios-permissoes.component';
import { UsuariosInstrutoresComponent } from './pages/usuarios/usuarios-instrutores/usuarios-instrutores.component';
import { UsuariosFormComponent } from './pages/usuarios/usuarios-form/usuarios-form.component';
import { UsuariosTrocarSenhaComponent } from './pages/usuarios/usuarios-trocar-senha/usuarios-trocar-senha.component';
import { InstructorsComponent } from './pages/instructors/instructors/instructors.component';
import { InstructorsFormComponent } from './pages/instructors/instructors-form/instructors-form.component';
import { InstructorsFormPageComponent } from './pages/instructors/instructors-form-page/instructors-form-page.component';
import { UserProductsComponent } from './dialogs/user-products/user-products.component';
import { UsersFormComponent } from './pages/users/users-form/users-form.component'

import { SimulatorCasosListarComponent } from './pages/simulador/simulator-casos-listar/simulator-casos-listar.component';
import { SimulatorCasosFormComponent } from './pages/simulador/simulator-casos-form/simulator-casos-form.component';
import { SimulatorCasosFluxogramaComponent } from './pages/simulador/simulator-casos-fluxograma/simulator-casos-fluxograma.component';

import { SimulatorAnamnesisListarComponent } from './pages/simulador/simulator-anamnesis-listar/simulator-anamnesis-listar.component';
import { SimulatorAnamnesisFormComponent } from './pages/simulador/simulator-anamnesis-form/simulator-anamnesis-form.component';

import { SimulatorFeedbacksListarComponent } from './pages/simulador/simulator-feedbacks-listar/simulator-feedbacks-listar.component';
import { SimulatorFeedbacksFormComponent } from './pages/simulador/simulator-feedbacks-form/simulator-feedbacks-form.component';

import { QuestionListComponent } from './pages/questions/question-list/question-list.component';
import { QuestionFormComponent } from './pages/questions/question-form/question-form.component';
import { QuizQuestionsComponent } from './pages/questions/quiz-questions/quiz-questions.component';

import { NotificationsComponent } from './pages/notifications/notifications.component';
import { RegistrationCodeComponent } from './pages/media/registration-code/registration-code.component';
import { CustomRouteReuseStategy } from './utils/CustomRouteReuseStategy';

import { TopicsListComponent } from './pages/topics/topics-list/topics-list.component';

import { ReportVoucherComponent } from './pages/reports/report-voucher/report-voucher.component';

import { ReportVoucherDetailsComponent } from './pages/reports/report-voucher-details/report-voucher-details.component';

import { ReportViewComponent } from './pages/reports/report-view/report-view.component';

import { CheckUserInfoComponent } from './pages/contracts/check-user-info/check-user-info.component';

import { JourneyStepsComponent } from './pages/journey/journey-steps/journey-steps.component';

import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { UsersDetailsComponent } from './pages/users/users-details/users-details.component';
import { ListarCursosComponent } from './pages/cursos/listar-cursos/listar-cursos.component';
import { CadastrarCursosComponent } from './pages/cursos/cadastrar-cursos/cadastrar-cursos.component';
import { TurmasListComponent } from './pages/turmas/turmas-list/turmas-list.component';
import { TurmasFormComponent } from './pages/turmas/turmas-form/turmas-form.component';
import { NotasListComponent } from './pages/notas/notas-list/notas-list.component';
import { QuizFormComponent } from './pages/quizzes/form/quiz-form.component';
import { QuizListComponent } from './pages/quizzes/list/quiz-list.component';
import { ReportUsersComponent } from './pages/reports/report-users/report-users.component';


const routes: Routes = [
	{
		path: '',
		component: EstruturaComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				component: HomeComponent
			},
			{
				path: 'turmas',
				component: TurmasListComponent
			},
			{
				path: 'turmas/cadastrar',
				component: TurmasFormComponent
			},
			{
				path: 'turmas/editar/:id',
				component: TurmasFormComponent
			},
			{
				path: 'calendario',
				component: CalendarioExibirComponent
			},
			{
				path: 'minhas_aulas',
				component: MinhasAulasComponent,
				data: { shouldReuse: true }
			},
			{
				path: 'minhas_aulas/activity',
				component: MinhasAulasAtividadeComponent
			},
			{
				path: 'minhas_aulas/create',
				component: MinhasAulasFormComponent
			},
			{
				path: 'minhas_aulas/edit/:id',
				component: MinhasAulasFormComponent
			},
			{
				path: 'minhas_aulas/schedule/:id',
				component: MinhasAulasAgendamentoComponent
			},
			{
				path: 'minhas_aulas/:id_aula/video/:id_video',
				component: MinhasAulasVideoComponent
			},
			{
				path: 'instructors',
				component: InstructorsComponent,
				data: { shouldReuse: true }
			},
			{
				path: 'instructors/new',
				component: InstructorsFormPageComponent
			},
			{
				path: 'instructors/:id/edit',
				component: InstructorsFormPageComponent
			},
			{
				path: 'contract/:studio_block_id/user_info',
				component: CheckUserInfoComponent
			},
			{
				path: 'usuarios',
				component: UsuariosComponent,
				data: { shouldReuse: true }
			},
			{
				path: 'usuario/:id/instrutores',
				component: UsuariosInstrutoresComponent
			},
			{
				path: 'usuario/:id/permissoes',
				component: UsuariosPermissoesComponent
			},
			{
				path: 'usuario/trocar_senha',
				component: UsuariosTrocarSenhaComponent
			},
			{
				path: 'usuario/:id/edit',
				component: UsuariosFormComponent
			},
			{
				path: 'usuario/create',
				component: UsuariosFormComponent
			},
			{
				path: 'users',
				component: UsersListComponent,
				data: { shouldReuse: true }
			},
			{
				path: 'users/create',
				component: UsersFormComponent
			},
			{
				path: 'users/:id',
				component: UsersDetailsComponent
			},
			{
				path: 'cursos',
				component: ListarCursosComponent,
			},
			{
				path: 'cursos/cadastrar',
				component: CadastrarCursosComponent,
			},
			{
				path: 'cursos/:id',
				component: CadastrarCursosComponent,
			},
			{
				path: 'faq',
				component: FaqComponent
			},

			{
				path: 'report/voucher',
				component: ReportVoucherComponent
			},

			{
				path: 'report/users',
				component: ReportUsersComponent,
			},

			{
				path: 'report/voucher/:code',
				component: ReportVoucherDetailsComponent
      		},

			{
				path: 'report/:table',
				component: ReportViewComponent
			},

			{
				path: 'simulador/anamnesis',
				component: SimulatorAnamnesisListarComponent
			},
			{
				path: 'simulador/anamnesis/create',
				component: SimulatorAnamnesisFormComponent
			},
			{
				path: 'simulador/anamnesis/:id/edit',
				component: SimulatorAnamnesisFormComponent
			},

			{
				path: 'simulador/feedbacks',
				component: SimulatorFeedbacksListarComponent
			},
			{
				path: 'simulador/feedbacks/create',
				component: SimulatorFeedbacksFormComponent
			},
			{
				path: 'simulador/feedbacks/:id/edit',
				component: SimulatorFeedbacksFormComponent
			},

			{
				path: 'simulador/:type',
				component: SimulatorCasosListarComponent,
				data: { shouldReuse: true }
			},
			{
				path: 'simulador/:type/create',
				component: SimulatorCasosFormComponent
			},
			{
				path: 'simulador/:type/:id/edit',
				component: SimulatorCasosFormComponent
			},
			{
				path: 'simulador/:type/:id/flow',
				component: SimulatorCasosFluxogramaComponent
			},
			{
				path: 'disciplina/:id',
				component: JourneyStepsComponent
			},

			{
				path: 'question',
				component: QuestionListComponent
			},
			{
				path: 'question/form',
				component: QuestionFormComponent
			},
			{
				path: 'question/form/:id',
				component: QuestionFormComponent
			},
			{
				path: 'quiz/:id',
				component: QuizQuestionsComponent
			},
			{
				path: 'notifications',
				component: NotificationsComponent,
				data: { shouldReuse: true }
			},
			{
				path: 'registration-code',
				component: RegistrationCodeComponent
			},
			{
				path: 'topics',
				component: TopicsListComponent
			},
			{
				path: ':tabela/list',
				component: ListarComponent
			},
			{
				path: ':tabela/create',
				component: FormComponent
			},
			{
				path: ':tabela/edit/:id',
				component: FormComponent
			},
			{
				path: 'notas-list',
				component: NotasListComponent
			},
			{
				path: 'prova',
				children: [
					{
						path: '',
						component: QuizListComponent,
					},
					{
						path: 'form',
						component: QuizFormComponent,
					},
					{
						path: 'form/:id',
						component: QuizFormComponent,
					}
				]
			}
		]
	},
	{
		path:'login',
		component: LoginComponent
	}
	,
	{ path: 'home',
		redirectTo: '/',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
