import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { ApiRequestService } from "../../../services/api-request.service";
import { PageEvent } from '@angular/material/paginator';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ProdutoSelecionado } from '../../../utils/ProdutoSelecionado';
import { EventEmitterService } from '../../../services/event-emitter.service';
import * as moment from 'moment';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {CdkTableModule } from '@angular/cdk/table';

@Component({
	selector: 'app-listar',
	templateUrl: './listar.component.html',
	styleUrls: ['./listar.component.scss']
})
export class ListarComponent implements OnInit {

	@ViewChild("tbl") table:any;

	params:any;
	estrutura:any;
	tabela:any = [];
	dados:any;
	tblRows:any[] = [];
	refreshProductSubscription:any;
	can_filter:boolean = false;
	has_multiple_filters:boolean = false;
	filter_name:string = "";
	filterByName:string = "";

	columnsToDisplay = [];

	constructor(
		private _notifications: NotificationsService,
		public apiRequest: ApiRequestService,
		private route: ActivatedRoute,
		private router: Router,
		private changeDetectorRefs: ChangeDetectorRef
	) {
		//this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	}

	ngOnInit() {

		this.route.params.subscribe(ret => {
			this.estrutura = undefined;
			this.columnsToDisplay = [];
			this.tabela = [];
			this.dados = undefined;

			this.params = ret;
			this.CarregarEstrutura();
		});

		this.refreshProductSubscription = EventEmitterService.get("refreshProduct").subscribe((res)=>{
     		this.CarregarDados();
 		});

		//Destrói subscription (durante a troca de rota. Não usar ngOnDestroy porque alguns componentes não são destruídos)
 		this.router.events.subscribe((event) => {
			this.refreshProductSubscription.unsubscribe();
		});
	}

	dropItens(event: CdkDragDrop<string[]>) {
		let previousIndex = this.tblRows.findIndex(row => row === event.item.data)
		moveItemInArray(this.tblRows, previousIndex, event.currentIndex);
		this.table.renderRows();
		this.SaveOrder();
	}


	CarregarEstrutura(){
		this.apiRequest.Get("crud/"+this.params.tabela+"/structure").subscribe((ret:any)=>{
			this.estrutura = ret;

			for(let k in this.estrutura.campos){

				if(this.estrutura.campos[k].mostrar_tabela){
					this.columnsToDisplay.push(k);
				}
				
				this.tabela.push({
					coluna: k,
					nome: this.estrutura.campos[k].nome,
					tipo: this.estrutura.campos[k].tipo,
					format: this.estrutura.campos[k].format,
					valores: this.estrutura.campos[k].valores
				});

			}

			//if(this.estrutura.additional_menu) this.columnsToDisplay.push("additional_menu");

			this.columnsToDisplay.push("acoes");

			if(this.estrutura.can_reorder) this.columnsToDisplay.unshift("reorder");

			if(this.estrutura.can_filter) this.can_filter = true;
			if(this.estrutura.has_multiple_filters) this.has_multiple_filters = true;
			if(this.estrutura.filter_name) this.filter_name = this.estrutura.filter_name;

			this.CarregarDados();

		});
	}

	pagAtual:number = 0;
	CarregarDados(pag:number = 0, pageSize:number = 25){

		if(this.dados!=undefined)
			this.dados.data = [];

		let url = "crud/"+this.params.tabela+"?page="+(pag+1)+"&pageSize="+pageSize;

		if(this.filterByName!="") url+= "&nameFilter="+this.filterByName;
		
		this.apiRequest.Get(url).subscribe((ret:any)=>{
			this.dados = ret;
			this.tblRows = this.dados.data;
		});

		this.pagAtual = pag;

	}

	RetornarValor(dados:any, estrutura:any){

		if(estrutura.format=="time"){
			dados[estrutura.coluna] = dados[estrutura.coluna].substr(0,5);
		}

		if(estrutura.tipo=="enum"){
			if(estrutura.valores[dados[estrutura.coluna]]!=undefined)
				return estrutura.valores[dados[estrutura.coluna]].nome;
		} else if(estrutura.tipo=="status"){
			return dados[estrutura.coluna] == 1 ? "Sim" : "Não"

		} else if(estrutura.tipo=="date"){

			moment.locale("pt-br");

			return moment(dados[estrutura.coluna]).format('L');
		}

		return dados[estrutura.coluna];
	}

	public AtualizarPaginacao(event?:PageEvent){
		this.CarregarDados(event.pageIndex, event.pageSize);
	}


	Remover(id:number){

		if( confirm("Tem certeza que deseja remover este item?") ){

			this.apiRequest.Delete("crud/"+this.params.tabela+"/"+id).subscribe((ret:any)=>{
				this._notifications.create("Sucesso!", "Registro removido com sucesso", NotificationType.Success, { timeOut: 5000 } );
				this.CarregarDados(this.pagAtual);
			});

		}

	}

	GetLink(link:string, element:any){

		link = link.replace("--ID--", element.id);

		return link;
	}

	SaveOrder() {

		let data:any = {
			table: this.estrutura.tabela,
			items: []
		};

		this.tblRows.forEach((v:any, i:number)=> data.items.push({id: v.id, order: i}) );

		this.apiRequest.Post("crud/"+this.params.tabela+"/reorder", data).subscribe();

	}

}
