import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ApiRequestService } from 'src/app/services/api-request.service';
import {MatDialog } from '@angular/material';
import { EditDateComponent } from 'src/app/dialogs/edit-date/edit-date.component';


export interface Turma {
  id: number;
  slug: string;
  name: string;
  area_id: any;
  area: any;
  curso_id: any;
  curso: any;
  journey_id: any;
  journeys: any;
  disciplina_id: any;
  disciplina: any;
  type: any;
  active: any;
  available_for_sale: any
  icon: string;
  image: string;
  created_at: string;
  updated_at: string;
}

export interface HttpPostResponse {
  data: Turma;
  message: string;
}

@Component({
  selector: 'app-turmas-form',
  templateUrl: './turmas-form.component.html',
  styleUrls: ['./turmas-form.component.scss'],
  providers: [DatePipe]
})

export class TurmasFormComponent implements OnInit {
  form: FormGroup = this.formBuilder.group({
    id: [null],
    slug: ['', Validators.required],
    name: ['', Validators.required],
    area: ['', Validators.required],
    curso: ['', Validators.required],
    type: ['', Validators.required],
    active: [false, Validators.required],
    available_for_sale: [false, Validators.required],
    icon: ['', Validators.required],
    image: ['', Validators.required]
  });

  turma: Turma;
  ativarSelecaoDisciplina: boolean = false;
  disciplinasList: any[] = [];
  disciplinasSelecionadas: any[] = [];
  id: number;
  isLoading = false;
  isSaveLoading = false;
  iconPreview: string;
  imagePreview: string;
  tipoList:any = [
    {
      label: 'Business to Business',
      value: 'b2b'
    },
    {
      label: 'Business to Consumer',
      value: 'b2c'
    }];
  editando: boolean = false;
  addDisciplina = true;

  formDisciplina: FormGroup = this.formBuilder.group({ //Usado temporariamente para salvar a disciplina selecionada
    disciplinaSelecionada: [{}, Validators.required],
    idDisciplinaSelecionada: [null],
    dataLiberacaoSelecionada: [null, Validators.required],
  });

  constructor(
		private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private api: ApiRequestService,
    private notifications: NotificationsService,
    private datePipe: DatePipe,
    public dialog: MatDialog

  ) { }

  ngOnInit() {
     this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.id = params['id'];
        this.getTurmaById();
        this.editando = true;
      }
    });

    this.CarregarAreas();
    this.CarregarCursos();
  }

  getTurmaById() {
    const url = `turmas/${this.id}`;
    this.isLoading = true;

    this.api.Get(url).subscribe(({
      next: (res: any) => {
        this.turma = res.data;
        this.preencheDadosFormulario(res.data);
        this.isLoading = false;
        // console.log(res.data);
        
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      }
    }));
  }

  save() {
    const url = `turmas/create`;
    const data = {
      ...this.form.value,
      disciplinas: (this.disciplinasSelecionadas)
    }
    
    this.isSaveLoading = true;


    this.api.Post(url, data).subscribe(({
      next: (res: HttpPostResponse) => {
        console.log(res);
        this.isSaveLoading = false;
        this.notifications.create("Sucesso!", res.message, NotificationType.Success, { timeOut: 2500 } );
        
        setTimeout(() => {
          this.router.navigate(['/turmas']);
        }, 1000);
      },
      error: (err) => {
        console.log(err);
      }
    }));
  }

  update() {
    const url = `turmas/update`;
    this.isSaveLoading = true;
    this.form.get('id').setValue(this.id);

    const data = {
      ...this.form.value,
      disciplinas: (this.disciplinasSelecionadas)
    };

    this.api.Post(url, data).subscribe(({
      next: (res: HttpPostResponse) => {
        console.log(res);
        this.isSaveLoading = false;
        this.notifications.create("Sucesso!", res.message, NotificationType.Success, { timeOut: 2500 } );

        setTimeout(() => {
          this.router.navigate(['/turmas']);
        }, 1000);
      },
      error: (err) => {
        console.log(err);
      }
    }));
  }

  preencheDadosFormulario(data: Turma) {
    const {
      slug,
      name,
      area_id,
      curso_id,
      type,
      active,
      available_for_sale,
      icon,
      image,
      id
    } = data;

    //Identifica se é B2B ou B2C
    const selectedType = this.tipoList.find(option => option.value === type);

    console.log("journeys", data.journeys);
    const disciplinasSelecionadas = data.journeys.map((item) => {
      return {
        journey_id: item.pivot.journey_id,
        journey_live: item.live,
        journey_name: item.name,
        available: item.pivot.available_at,
        availableFormatted: this.datePipe.transform(item.pivot.available_at, 'dd/MM/yyyy'),
      };
    });

    

    this.form.patchValue({
      slug,
      name,
      area: area_id,
      curso: curso_id,
      type: selectedType.value,
      active,
      available_for_sale,
      icon,
      image,
      id
    });

    this.disciplinasSelecionadas = disciplinasSelecionadas;

    this.CarregarDisciplinas();

  }
  
  GetField(field: string, form: FormGroup): AbstractControl {
    return form.get(field);
  }
  
  GetError(field: string, form: FormGroup): string {
    if (form.get(field).hasError('required')) {
      return "Este campo é obrigatório";
    }
    return '';
  }

  onIconChanged(event) {
    const file: File = event.target.files[0];
  
    const reader = new FileReader();
  
    reader.onload = (e: any) => {
      this.iconPreview = e.target.result;
    };
  
    reader.readAsDataURL(file);
  
    this.api.UploadPosS3(file).subscribe((res: any) => {
      console.log(res);
      this.form.get('icon').setValue(res.file);
    });
  }

  onFileChanged(event) {
		let f:File = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imagePreview = e.target.result;
    };

    reader.readAsDataURL(f);

		this.api.UploadPosS3(f).subscribe((res:any)=>{
			console.log(res);
      this.form.get('image').setValue(res.file);
		});
	}

  onSubmit() {
    if (this.turma) {
      this.update();
    } else {
      this.save();
    }
  }



  areas: any[]; 
  CarregarAreas(){

		this.api.Get("areas").subscribe((response:any[])=>{
			this.areas = response;
		}, error=>{
			console.error("ERRO", error)
		});

	}

  cursos: any[]; 
  CarregarCursos(){

		this.api.Get("cursos").subscribe((response:any[])=>{
			this.cursos = response;
		}, error=>{
			console.error("ERRO", error)
		});

	}

	async CarregarDisciplinas(limparDisciplinasSelecionadas=false){

    if(this.form.get('curso').value){
      
      if(limparDisciplinasSelecionadas){
        this.disciplinasSelecionadas = [];
      }
      
      const url = `cursos/disciplinas/`+this.form.get('curso').value;
      
      this.api.Get(url).subscribe((response:any[])=>{
        this.disciplinasList = response;
        if (this.disciplinasList.length > 0){
          // console.log("this.disciplinasList");
          this.ativarSelecaoDisciplina = true;
        }
      }, error=>{
        this.disciplinasList = [];
        if (error.status === 404){
          this.notifications.create("Atenção:", "Este curso não possui disciplinas vinculadas", NotificationType.Error, { timeOut: 3500 } );
       }else{
          this.notifications.create("Erro!", error.message, NotificationType.Error, { timeOut: 2500 } );
       }
      });
      
    }
	}



  Back(): void {
		this.router.navigate(['/turmas']);
	}
  
  showAddDisciplinaSection() {
    this.addDisciplina = true;
  }

  async salvarDisciplina() {
    const novaDisciplina = this.formDisciplina.value.disciplinaSelecionada;
  
    // Verifica se a disciplina já existe em disciplinasSelecionadas
    const disciplinaExistente = this.disciplinasSelecionadas.some(disciplina => disciplina.journey_id === novaDisciplina.journey_id);
  
    if (disciplinaExistente) {
      this.notifications.create("Erro!", 'Disciplina já existe nesta turma!', NotificationType.Error, { timeOut: 1500 } );
      return;
    }
  
    novaDisciplina.available = this.datePipe.transform(this.formDisciplina.value.dataLiberacaoSelecionada, 'yyyy-MM-dd HH:mm:ss');
    novaDisciplina.availableFormatted = this.datePipe.transform(this.formDisciplina.value.dataLiberacaoSelecionada, 'dd/MM/yyyy');
    
    // Adiciona a nova disciplina à lista de disciplinas selecionadas
    this.disciplinasSelecionadas = [...this.disciplinasSelecionadas, novaDisciplina];
  
  
    this.formDisciplina.reset(); // Limpa o formulário após adicionar a disciplina
  }

  async removerDisciplina(disciplina_id) {
  
    const disciplinasFiltradas = this.disciplinasSelecionadas.filter(disciplina => disciplina.journey_id != disciplina_id);

    this.disciplinasSelecionadas = disciplinasFiltradas;

    this.notifications.create(
      "Sucesso!", 
      "Disciplina removida com sucesso!", 
      NotificationType.Success, 
      { timeOut: 2500 } 
    );
    
  }

  editaData(journey_id: number) {
    console.log(this.disciplinasSelecionadas);
    
    const dialogRef = this.dialog.open(EditDateComponent, {
      width: '600px',
      height: '180px',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!!response) {
        this.disciplinasSelecionadas.map((value) => {
          if (value.journey_id == journey_id) {
            value.available = this.datePipe.transform(response, 'yyyy-MM-dd HH:mm:ss');
            value.availableFormatted = this.datePipe.transform(response, 'dd/MM/yyyy');
            this.notifications.create(
              "Sucesso!", 
              "Data alterada com sucesso!", 
              NotificationType.Success, 
              { timeOut: 2500 } 
            );
          }
        });
      }
    });
  }
}
