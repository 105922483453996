import { environment } from "../../environments/environment";

export const Config: any = {
	tinyMCE: {
		init: {
			theme: 'modern',
			mobile: {
				theme: 'mobile'
			},
			relative_urls: true,
			convert_urls: false,
			// plugins: [
			// 'advlist autolink lists link image charmap print preview anchor',
			// 'searchreplace visualblocks code fullscreen',
			// ],
			// toolbar: 'undo redo | insert | styleselect | fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image code | responsivefilemanager',
			language: 'pt_BR',
			language_url: 'assets/lang/pt_BR.js',
			browser_spellcheck: true,
			images_upload_url: environment.apiUrl.replace("admin/","") + "extraUpload/pos/s3",
  			automatic_uploads: true,
			inline_styles: true,
			content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
			entity_encoding : "raw",
			paste_word_valid_elements: "b,strong,i,em,h1,h2,u,p,ol,ul,li,a[href],span,color,font-size,font-color,font-family,mark,table,tr,td",
			paste_retain_style_properties: "all",
			paste_convert_word_fake_lists: false,
			table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
			plugins: 'print preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons',
			external_plugins: {
				'responsivefilemanager': '/assets/tinymce/plugins/responsivefilemanager/plugin.min.js'
			},
			menubar: 'file edit view insert format tools table',
			toolbar: 'undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media link anchor | ltr rtl',
			toolbar_sticky: true,
			importcss_append: true,
		}
	},
	pyramid: [
		{key: "reading", value: "Leitura"},
		{key: "audiovisual", value: "Audiovisual"},
		{key: "demonstration", value: "Demonstração"},
		{key: "discussion", value: "Discussão em Grupo"},
		{key: "simulate", value: "Fazer/Simular"},
		{key: "teach", value: "Ensinar os Outros"}
	],
	videoTypes: [
		{
			key: "videoclass",
			value: "Videoaula"
		},
		{
			key: "interview",
			value: "Entrevista"
		}
	],
	knowledgeArea: [
		{
			key: "medicine",
			value: "Medicina"
		},
		{
			key: "nursing",
			value: "Enfermagem"
		},
		{
			key: "nursing_tech",
			value: "Técnico em Enfermagem"
		},
	],
	fileTypes: [
		{
			key: "powerpoint",
			value: "PowerPoint"
		},
		{
			key: "tp",
			value: "Teleprompter"
		},
		{
			key: "extra",
			value: "Material Complementar"
		},
		{
			key: "private",
			value: "Privado"
		}
	],
	contentStatus: [
		{
			key: "registered",
			value: "Cadastrada"
		},
		{
			key: "editing",
			value: "Editando"
		},
		{
			key: "recording",
			value: "Regravação"
		},
		{
			key: "on_approval",
			value: "Aguardando aprovação"
		},
		{
			key: "scheduled",
			value: "Gravação agendada"
		},
		{
			key: "approved",
			value: "Aprovada"
		},
		{
			key: "reproved",
			value: "Reprovada"
		}
	],
	roles: [
		{
			key: "admin",
			value: "Admin",
			info: "Permite acesso ao sistema administrativo",
			require: ["master", "coordenator", 'secretary']
		},
		{
			key: "instructor",
			value: "Professor",
			info: "Habilita usuário como professor no sistema",
			require: []
		},
		{
			key: "scp_instructor",
			value: "Professor SCP",
			info: "Habilita o professor como SCP (Sociedade em Conta de Participação)",
			require: ["master"]
		},
		{
			key: "videomaker",
			value: "Video Maker",
			info: "Habilita permissões de videomaker para o usuário",
			require: ["master"]
		},
		{
			key: "secretary",
			value: "Secretaria",
			info: "Habilita permissões de secretaria para o usuário",
			require: ["master"]
		},
		{
			key: "is",
			value: "Inside Sales",
			info: "Habilita permissões de Inside Sales para o usuário",
			require: ["master"]
		},
		{
			key: "editor",
			value: "Editor",
			info: "Habilita permissões para administração do Blog",
			require: ["master"]
		},
		{
			key: "coordenator",
			value: "Coordenador",
			info: "Habilita permissões de coordenador para o usuário",
			require: ["master"]
		},
		{
			key: "simulator-tester",
			value: "Simulador - Tester",
			info: "Habilita permissões de testar casos não publicados do Simulador",
			require: ["master"]
		},
		{
			key: "simulator-master",
			value: "Simulador - Master",
			info: "Habilita permissões de gestão do Simulador",
			require: ["master"]
		},
		{
			key: "media",
			value: "Mídia",
			info: "Habilita permissões mídia, como por exemplo geração de códigos de cadastro",
			require: []
		},
		{
			key: "calendar",
			value: "Calendário",
			info: "Habilita permissões de visualização do calendário e adição de bloqueios.",
			require: []
		},
		{
			key: "master",
			value: "Master",
			info: "Usuário master do sistema, gerencia todas as funções",
			require: ["master"]
		}
	],
	userPlan: {
		status: {
			trial: {
				label: "Em Teste",
				style: "info"
			},
			canceled: {
				label: "Cancelado",
				style: "fail"
			},
			active: {
				label: "Ativo",
				style: "success"
			},
			payment_error: {
				label: "Erro no pagamento",
				style: "fail"
			},
			created: {
				label: "Criado",
				style: "info"
			},
			pending: {
				label: "Pendente de pagamento",
				style: "info"
			}
		},
		periodicity: {
      once: {
				label: "Única",
			},
			monthly: {
				label: "Mensal",
			},
			semiannual: {
				label: "Semestral",
			},
			annual: {
				label: "Anual",
			},
			prompt_annual: {
				label: "Anual à Vista",
			},
			beta: {
				label: "Beta",
			},
			Voucher: {
				label: "Voucher",
			},
			voucher: {
				label: "Voucher",
			},
			b2b: {
				label: "B2B",
			}
		},
		payments_status: {
			CAPTURED: {
				label: "Pago",
				style: "success"
			},
			DECLINED: {
				label: "Negado",
				style: "fail"
			},
			VOIDED: {
				label: "Estornado",
				style: "warning"
			}
		}
	}
}
